import React, {
  useContext,
  useCallback,
  useRef,
  useMemo,
  useState,
  useEffect,
  FunctionComponent,
} from 'react'
import compact from 'just-compact'
import { Modal } from '@heights/heights-ui-library'
import cookies from 'nookies'
import { PrepassContext, SubscribeFormContext } from '@/context'
import { dropSubscribeCookie } from '@/utils/browser'
import { ContentModalProps } from '@heights/heights-ui-library/lib/esm/organisms/modal/variants/content-modal'
import { modal } from '@/schemas/modal'
import { useSectionData } from '@/hooks'
import { asResolverQuery } from '@/utils/groq'
import { transformImage } from '@/utils/contentful'
import { ComponentPicker } from '../ComponentPicker'

export const modalQueryBuilder = modal.builder.subquery({
  components: asResolverQuery<{ _id: string; _type: string }[]>(
    'fields.components',
    '{_id,_type}',
    true
  ),
  linkedContent: asResolverQuery<{ _id: string; _type: string }[]>(
    'fields.linkedContent',
    '{_id,_type}',
    true
  ),
})

const [, modalType] = modalQueryBuilder.first().use()

type Props = {
  modal: NonNullable<typeof modalType>
  preventOpening?: boolean
}

export const LayoutModalsModal: FunctionComponent<Props> = ({
  modal,
  preventOpening,
}) => {
  const { isPrepass } = useContext(PrepassContext)

  const portalRef = useRef<any | null>(null)
  const cookieExists = useRef(false)
  const timeoutRef = useRef<any>()
  const subscribeFormContext = useContext(SubscribeFormContext)
  const [renderModal, setRenderModal] = useState(!modal.fields?.autoOpen)
  const [subscribeSuccess, setSubscribeSuccess] = useState(false)
  const modalComponents = useMemo(() => {
    const components = compact(modal.components ?? [])
    if (components && components.length > 2) {
      if (subscribeSuccess) return components.slice(2)
      return components
    }

    return components ?? []
  }, [modal, subscribeSuccess])

  const section = useSectionData(modalComponents?.[0]?._id)

  const onModalClose = useCallback(() => {
    if (modal.fields?.autoOpen && modal.fields?.autoOpenCookiename) {
      dropSubscribeCookie('seen', 90, modal.fields?.autoOpenCookiename)
    }
  }, [modal])

  useEffect(() => {
    if (modal.fields?.autoOpen && modal.fields?.autoOpenCookiename) {
      cookieExists.current = !!cookies.get()?.[modal.fields?.autoOpenCookiename]

      const isCurrentUrlIsIncluded =
        !modal.fields?.autoOpenInclusions?.length ||
        !!modal.fields?.autoOpenInclusions.some((item) => {
          return window.location.pathname.startsWith(`/${item}/`)
        })

      const isCurrentUrlIsExcluded = !!modal.fields?.autoOpenExclusions?.some(
        (item) => {
          return window.location.pathname.startsWith(`/${item}/`)
        }
      )

      if (
        !isCurrentUrlIsExcluded &&
        isCurrentUrlIsIncluded &&
        !cookieExists.current
      ) {
        setRenderModal(true)

        if (!preventOpening) {
          timeoutRef.current = window.setTimeout(() => {
            portalRef.current.openPortal()
          }, modal.fields.autoOpenDelay ?? 0)
        }
      }
    }

    return () => window.clearTimeout(timeoutRef.current)
  }, [modal, preventOpening])

  if (renderModal || isPrepass) {
    if (
      !modal.fields?.modalVariant ||
      modal.fields?.modalVariant === 'content'
    ) {
      return (
        <SubscribeFormContext.Provider
          value={{
            cookieName:
              modal.fields?.autoOpenCookiename ??
              subscribeFormContext.cookieName,
            success: subscribeSuccess,
            setSuccess: setSubscribeSuccess,
          }}
        >
          {isPrepass && <ComponentPicker data={modalComponents} />}
          <Modal
            id={
              modal.fields?.trackingId ?? modal.fields?.reference ?? modal._id
            }
            fullHeight={false}
            trackId={modal.fields?.trackingId}
            modalHeaderContent={
              modalComponents?.[0] ? (
                <ComponentPicker data={[modalComponents[0]]} />
              ) : null
            }
            variant="content"
            size={
              (modal.fields?.modalSize ??
                'default') as ContentModalProps['size']
            }
            ref={portalRef}
            onModalClose={onModalClose}
          >
            {!!modalComponents?.[1] && (
              <ComponentPicker data={[modalComponents[1]]} />
            )}
          </Modal>
        </SubscribeFormContext.Provider>
      )
    }

    if (modal.fields.modalVariant === 'split') {
      return (
        <SubscribeFormContext.Provider
          value={{
            cookieName:
              modal.fields.autoOpenCookiename ??
              subscribeFormContext.cookieName,
            success: subscribeSuccess,
            setSuccess: setSubscribeSuccess,
          }}
        >
          {isPrepass && (
            <>
              <ComponentPicker data={modalComponents} />
              <ComponentPicker data={[modalComponents[0]]} />
              <ComponentPicker data={[modalComponents[1]]} />
            </>
          )}
          <Modal
            fullHeight={false}
            trackId={modal.fields?.trackingId}
            variant="split"
            ref={portalRef}
            onModalClose={onModalClose}
            leftContent={
              modalComponents?.[0] && (
                <ComponentPicker data={[modalComponents[0]]} />
              )
            }
            rightContent={
              !!modalComponents?.[1] && (
                <ComponentPicker data={[modalComponents[1]]} />
              )
            }
          />
        </SubscribeFormContext.Provider>
      )
    }

    if (modal.fields.modalVariant === 'nutritional' && modalComponents?.[0]) {
      const image =
        transformImage(section?.backgroundImage?.fields, {
          width: 1920,
        }) ?? undefined

      const mobileImage =
        transformImage(section?.mobileBackgroundImage?.fields, {
          width: 1920,
        }) ?? undefined

      if (!image) {
        return null
      }

      return (
        <SubscribeFormContext.Provider
          value={{
            cookieName:
              modal.fields.autoOpenCookiename ??
              subscribeFormContext.cookieName,
            success: subscribeSuccess,
            setSuccess: setSubscribeSuccess,
          }}
        >
          <Modal
            defaultOpen={isPrepass}
            ref={portalRef}
            trackId={modal.fields?.trackingId}
            variant="image"
            closePortal={() => portalRef.current.closePortal()}
            image={image}
            mobileImage={mobileImage}
            onModalClose={onModalClose}
          />
        </SubscribeFormContext.Provider>
      )
    }
  }

  return null
}
