import { FALLBACK_PRODUCT_IMAGE } from '@/config/products'
import { ChargeIntervalSubtitles } from '@/constants'
import { transformImage } from '@/utils/contentful'
import { normalizeShopifyId } from '@/utils/shopify'
import { useCallback } from 'react'
import type { ProductVariantEntry } from './useAllProductVariants'
import { formatVariantSubtitle } from '@/utils/formatVariantSubtitle'
import { useCurrency } from '@/hooks'

export function useCartSelect(currencyCode: string) {
  const { locale } = useCurrency()

  return useCallback(
    (item: ProductVariantEntry) => {
      const image =
        item.variant.featuredImage ??
        item.product.productImages?.[0] ??
        item.product.productImage

      const tranformedImage =
        'src' in image
          ? image
          : transformImage(image, {
              width: 100,
              height: 100,
            })

      return {
        id: normalizeShopifyId(item.variant.variantId),
        title: item.product.name,
        subtitle:
          formatVariantSubtitle(item.variant, locale, currencyCode) ||
          (item.variant.isSubscription
            ? ChargeIntervalSubtitles[item.variant.chargeIntervalType] ?? ''
            : ''),
        href: item.product.href,
        label: item.variant.saving,
        image: tranformedImage ?? FALLBACK_PRODUCT_IMAGE,
        price: item.variant.costPerChargeFrequency[currencyCode] ?? 0,
        quantity: item.line?.quantity ?? 0,
      }
    },
    [locale, currencyCode]
  )
}
