import React, { ComponentType, createElement } from 'react'
import dynamic from 'next/dynamic'
import { ContentfulSection } from './components/renderers/ContentfulSection'

export type SingleComponentPickerItem = {
  _id: string
  _type: string
  type?: string // @README only for the "section" content type - prefetching this beforehand speeds up post fetching
}

export type SingleComponentPickerProps = {
  index?: number
  item: SingleComponentPickerItem
  overrides?: Record<
    string,
    ComponentType<{
      data: SingleComponentPickerItem
    }>
  >
}

const DynamicContentfulHero = dynamic(
  () => import('./components/renderers/ContentfulHero')
)
const DynamicPdpHeroWrapper = dynamic(
  () => import('./components/pdp-hero-wrapper')
)
const DynamicContentfulQuoteSection = dynamic(
  () => import('./components/renderers/ContentfulQuoteSection')
)
const DynamicContentfulTestimonialSection = dynamic(
  () => import('./components/renderers/ContentfulTestimonialSection')
)
const DynamicContentfulFeaturesSection = dynamic(
  () => import('./components/renderers/ContentfulFeaturesSection')
)
const DynamicContentfulSectionContentHighlight = dynamic(
  () => import('./components/renderers/ContentfulSectionContentHighlight')
)
const DynamicContentfulBannerSection = dynamic(
  () => import('./components/renderers/ContentfulBannerSection')
)
const DynamicContentfulTimelineSection = dynamic(
  () => import('./components/renderers/ContentfulTimelineSection')
)
const DynamicContentfulArticleVideo = dynamic(
  () => import('./components/renderers/ContentfulArticleVideo')
)
const DynamicContentfulArticleCtaStrip = dynamic(
  () => import('./components/renderers/ContentfulArticleCtaStrip')
)
const DynamicContentfulSubscriptionSection = dynamic(
  () => import('./components/renderers/ContentfulSubscriptionSection')
)
const DynamicContentfulGridSection = dynamic(
  () => import('./components/renderers/ContentfulGridSection')
)
const DynamicContentfulMediaPlaybackCtaSection = dynamic(
  () => import('./components/renderers/ContentfulMediaPlaybackCtaSection')
)
const DynamicTolstoyWidget = dynamic(
  () => import('./components/renderers/ContentfulTolstoyWidget')
)

export const SingleComponentPicker: React.FC<SingleComponentPickerProps> = ({
  index = 0,
  item,
  overrides = {},
}) => {
  if (!item._type) return null
  const typename = item._type
  const key = `${typename}-${index}`

  if (Object.prototype.hasOwnProperty.call(overrides, item._type)) {
    return createElement(overrides[item._type], {
      data: item,
    })
  }

  switch (item._type) {
    case 'hero':
      return <DynamicContentfulHero heroData={item} />
    case 'heroSection':
      return <DynamicContentfulHero heroSectionData={item} />
    case 'quoteSection':
      return (
        <DynamicContentfulQuoteSection
          data={{ _type: 'quoteSection', _id: item._id }}
        />
      )
    case 'testimonialSection':
      return <DynamicContentfulTestimonialSection data={item} />
    case 'featureSection':
      return <DynamicContentfulFeaturesSection data={item} />
    case 'bannerSection':
      return <DynamicContentfulBannerSection id={key} data={item} />
    case 'timelineSection':
      return <DynamicContentfulTimelineSection data={item} />
    case 'pdpHero':
      return <DynamicPdpHeroWrapper cmsData={item} />
    case 'section':
      return <ContentfulSection key={key} data={item} />
    case 'contentHighlightSection':
      return <DynamicContentfulSectionContentHighlight data={item} />
    case 'articleVideo':
      return <DynamicContentfulArticleVideo data={item} />
    case 'articleCtaStrip':
      return <DynamicContentfulArticleCtaStrip data={item} />
    case 'subscriptionSection':
      return (
        <DynamicContentfulSubscriptionSection
          subscribeFormId={key}
          data={item}
        />
      )
    case 'mediaPlaybackCta':
      return <DynamicContentfulMediaPlaybackCtaSection data={item} />
    case 'ingredientsSection':
      // @TODO remove from Contentful
      return null
    case 'gridSection':
      return <DynamicContentfulGridSection id={key} data={item} />
    case 'tolstoyWidget':
      return <DynamicTolstoyWidget data={item} />
    case 'experimentContainer':
    default:
      // experiment containers are now handled on the component picker level
      return null
  }
}

export default SingleComponentPicker
