import { useShallowPickerData } from '@/hooks'
import { ReferenceType } from '@/schemas/ReferenceType'
import React, { ComponentType, FC, useContext, useMemo, useState } from 'react'
import compact from 'just-compact'
import { bgColourPicker } from '@heights/heights-utils'
import {
  SingleComponentPicker,
  SingleComponentPickerItem,
} from '../single-component-picker'
import { TrackingContainer } from '../TrackingContainer'
import { SectionContainerContext } from '@heights/heights-context'
import { groupSiblingsBy } from '@/utils'
import { ComponentPickerExperiments } from '../ComponentPickerExperiments/ComponentPickerExperiments'
import { PrepassContext } from '@/context'

interface ComponentPickerProps {
  data?: (ReferenceType | Pick<SingleComponentPickerItem, '_id'>)[]
  overrides?: Record<
    string,
    ComponentType<{
      data: SingleComponentPickerItem
    }>
  >
}

export const ComponentPicker: FC<ComponentPickerProps> = ({
  data = [],
  overrides,
}) => {
  const { parentSection, backgroundColor } = useContext(SectionContainerContext)
  const { isPrepass } = useContext(PrepassContext)
  const [experiments, setExperiments] = useState<Record<string, boolean>>({})
  const queriedData = useShallowPickerData(data)
  const experimentIds = useMemo(
    () =>
      compact(queriedData?.map((item) => item?.experiment?.experimentId) ?? []),
    [queriedData]
  )

  const groupedSections = useMemo(() => {
    if (!queriedData) return []

    const afterGoogleOptimize = queriedData.reduce<typeof queriedData>(
      (acc, item) => {
        if (item?._type === 'experimentContainer') {
          const isVariant = !!(item.experiment?.experimentId
            ? experiments[item.experiment.experimentId]
            : false)
          if (isPrepass) {
            acc.push(
              ...(item.experiment?.variantItems ?? []),
              ...(item.experiment?.controlItems ?? [])
            )
          } else {
            acc.push(
              ...(isVariant
                ? item.experiment?.variantItems ?? []
                : item.experiment?.controlItems ?? [])
            )
          }
        } else {
          acc.push(item)
        }
        return acc
      },
      []
    )

    return groupSiblingsBy(compact(afterGoogleOptimize), (v) => {
      if (v.fields.backgroundColor || v._type === 'section') {
        return bgColourPicker(v.fields.backgroundColor ?? 'white')
      }
      if (v._type === 'pdpHero') {
        return 'bright'
      }
      return v
    })
  }, [experiments, queriedData, isPrepass])

  if (!queriedData) {
    return null
  }

  return (
    <>
      <ComponentPickerExperiments
        ids={experimentIds}
        onUpdated={setExperiments}
      />
      {groupedSections.map((group) => {
        if (group.length === 0) return null
        return group.map((item, index) => {
          const typename = item._type

          const reference =
            'reference' in item.fields ? item.fields.reference : item._id
          const key = `${typename}-${reference || index}`
          const trackingId =
            'trackingId' in item.fields ? item.fields?.trackingId : undefined

          let groupKey: 'start' | 'end' | 'contained' | null = null
          if (group.length > 1) {
            if (index === 0) groupKey = 'start'
            else if (index === group.length - 1) groupKey = 'end'
            else groupKey = 'contained'
          }

          return (
            <SectionContainerContext.Provider
              key={key}
              value={{ backgroundColor, parentSection, group: groupKey }}
            >
              <TrackingContainer id={trackingId} contentfulId={item._id}>
                <SingleComponentPicker
                  index={index}
                  item={item}
                  overrides={overrides}
                />
              </TrackingContainer>
            </SectionContainerContext.Provider>
          )
        })
      })}
    </>
  )
}

export default ComponentPicker
