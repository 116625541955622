import { IntervalPlanLabels } from '../../constants'
import { CartFieldsFragmentFragment } from '@/graphql/storefront'
import type { ProductsMapModel, ProductVariantModel } from '@/types'
import { decodeShopifyId } from './decodeShopifyId'

export function ecommerceInfoFromLineItems(
  lineItems: CartFieldsFragmentFragment['lines']['edges'],
  internalProducts: ProductsMapModel = {}
) {
  const internalEntries = Object.values(internalProducts)
  const allVariants = internalEntries.reduce<ProductVariantModel[]>(
    (acc, product) => {
      return acc.concat(product.variants)
    },
    []
  )

  return lineItems.map((lineItem) => {
    const productIdNum = decodeShopifyId(lineItem.node.merchandise.product.id)
    const variantIdNum = decodeShopifyId(lineItem.node.merchandise.id)
    const internalVariant = allVariants.find(
      (entry) =>
        decodeShopifyId(entry.productId) === productIdNum &&
        decodeShopifyId(entry.variantId) === variantIdNum
    )

    const internalVariantName = !internalVariant
      ? null
      : internalVariant.highlightLabel ??
        (internalVariant.isSubscription
          ? IntervalPlanLabels[internalVariant.chargeIntervalType]
          : null)

    const obj = {
      id: lineItem.node.merchandise.product.id,
      name: lineItem.node.merchandise.title,
      productId: lineItem.node.merchandise.product.id,
      variantId: lineItem.node.merchandise.id,
      productName:
        internalVariant?.name ?? lineItem.node.merchandise.product.title,
      variantName: internalVariantName ?? lineItem.node.merchandise.title,
      quantity: lineItem.node.quantity || 1,
      price: parseFloat(
        String(lineItem.node.estimatedCost.subtotalAmount.amount)
      ),
    }
    return obj
  })
}
