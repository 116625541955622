import { useMemo } from 'react'
import type { ProductModel } from '@/types/ProductModel'
import { useProducts } from './useProducts'

export function useProduct(productId?: number) {
  const products = useProducts()
  return useMemo<ProductModel | null>(
    () => (productId ? products?.[productId] ?? null : null),
    [products, productId]
  )
}
