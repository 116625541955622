import { ProductVariantModel } from '@/types'
import { formatVariantFormattedString } from './formatVariantFormattedString'

export function formatVariantSubtitle(
  variant: ProductVariantModel,
  locale: string,
  currencyCode: string
) {
  const subtitle = variant.subtitle
  if (!subtitle) return null
  return formatVariantFormattedString(subtitle, variant, locale, currencyCode)
}
