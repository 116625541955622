import type React from 'react'
import compact from 'just-compact'
import { Nav } from '@heights/heights-ui-components'
import { contentfulLinkAsUrl } from './contentfulLinkAsUrl'
import { transformImage } from './transformImage'
import type {
  MenuItemWithSubmenuType,
  ShallowMenuItemType,
} from '@/hooks/useMenu'

type NavProps = React.ComponentProps<typeof Nav>
type NavData = NonNullable<NavProps['menuItems']>

export function contentfulMenuItemAsNavData(
  menuItem: MenuItemWithSubmenuType
): NavData[number] {
  const hasSubmenus = !!menuItem.submenus
  const allSubmenuItems =
    menuItem.submenus?.reduce<ShallowMenuItemType[]>(
      (all, submenu) => all.concat(compact(submenu?.menuItems ?? []), []) ?? [],
      []
    ) ?? []

  return {
    label: menuItem.fields?.label ?? '',
    mobileLabel: menuItem.link?.fields?.label ?? menuItem.fields?.label ?? '',
    description: menuItem.fields?.description,
    image:
      transformImage(menuItem?.image?.fields, {
        width: 1000,
        aspectRatio: 16 / 9,
        objectFit: 'cover',
      }) ?? undefined,
    url: hasSubmenus
      ? undefined
      : menuItem.link
      ? contentfulLinkAsUrl(menuItem.link)
      : '/',
    trackingId:
      menuItem.fields?.trackingId ??
      menuItem.link?.fields?.trackingId ??
      undefined,
    children: hasSubmenus
      ? allSubmenuItems.map((submenuItem) => ({
          tags:
            submenuItem?.fields?.badges?.map((badge) => ({
              label: badge,
            })) ?? [],
          label:
            submenuItem.fields?.label ??
            submenuItem.link?.fields?.label ??
            undefined,
          image:
            transformImage(submenuItem?.image?.fields, {
              width: 500,
              aspectRatio: 1,
            }) ?? undefined,
          trackingId: menuItem.fields?.trackingId ?? undefined,
          url: submenuItem.link ? contentfulLinkAsUrl(submenuItem.link) : '/',
          description: submenuItem.fields?.description,
        }))
      : undefined,
  }
}
