import type { CSSProperties } from 'react'
import type { ContentfulMediaAssetFieldsFragmentFragment } from '@/graphql/contentful'
import { contentfulMediaAssetAsImageAttrs } from './contentfulMediaAssetAsImageAttrs'
import { transformImageUrl, Options } from './transformImageUrl'
import { asset } from '@/schemas'

export type ImageTransformOptions = {
  width: number
  height?: number
  aspectRatio?: number
  sizes?: string
  fullscreen?: boolean
  layout?: 'constrained' | 'fixed' | 'fullWidth'
  objectFit?: CSSProperties['objectFit']
  objectPosition?: CSSProperties['objectPosition']
  transformOptions?: Options
}

export const BLUR_DATA_URL_SIZE = 4

export function transformImage(
  image:
    | Omit<ContentfulMediaAssetFieldsFragmentFragment, '__typename' | 'sys'>
    | typeof asset.asset['fields']
    | null
    | undefined,
  imageOptions: ImageTransformOptions
) {
  if (!image) return null

  const imageUrl =
    'url' in image ? image.url : 'file' in image ? image.file?.url : null
  const width =
    'width' in image
      ? image.width
      : 'file' in image
      ? image.file?.details?.image?.width
      : null
  const height =
    'height' in image
      ? image.height
      : 'file' in image
      ? image.file?.details?.image?.height
      : null

  if (imageUrl && width && height) {
    const aspectRatio = imageOptions.aspectRatio || width / height
    const imageObject = contentfulMediaAssetAsImageAttrs(
      {
        title: image.title,
        description: image.description,
        width: imageOptions.width,
        height:
          imageOptions?.height ?? Math.round(imageOptions.width / aspectRatio),
        url: transformImageUrl(imageUrl, {
          quality: 90,
          width: imageOptions.width,
          ...(typeof imageOptions.aspectRatio === 'number'
            ? {
                height: Math.round(imageOptions.width / aspectRatio),
                fit: 'fill',
                focus: 'center',
              }
            : {}),
          ...imageOptions.transformOptions,
        }),
      },
      {
        ...(imageOptions.fullscreen
          ? { fullscreen: imageOptions.fullscreen }
          : {}),
        ...(imageOptions.sizes ? { sizes: imageOptions.sizes } : {}),
        ...(imageOptions.layout ? { layout: imageOptions.layout } : {}),
        ...(imageOptions.objectFit
          ? { objectFit: imageOptions.objectFit }
          : {}),
        ...(imageOptions.objectPosition
          ? { objectPosition: imageOptions.objectPosition }
          : {}),
        blurDataURL:
          typeof imageOptions.aspectRatio === 'number'
            ? transformImageUrl(imageUrl, {
                quality: 20,
                width: BLUR_DATA_URL_SIZE,
                height: Math.ceil(
                  BLUR_DATA_URL_SIZE / imageOptions.aspectRatio
                ),
                fit: 'fill',
                focus: 'center',
                ...imageOptions.transformOptions,
              })
            : undefined,
      }
    )

    return imageObject
  }

  return null
}
