import { SocialData } from './SocialData'

// @README this was also hardcoded in Gatsby - leaving for now
export const SeoData = Object.freeze({
  siteUrl:
    process.env.SITE_URL ??
    process.env.VERCEL_URL ??
    'https://www.yourheights.com',
  title: 'Heights',
  defaultTitle: 'Brain Health and Nutrition',
  copyright: 'Heights HQ',
  description:
    'We provide the daily nutrients, knowledge and network you need to elevate your cognitive potential and brain health. Learn more!',
  social: SocialData,
  images: {
    heroCloud:
      process.env.NEXT_PUBLIC_SEO_IMG_HERO_CLOUD ??
      'https://images.ctfassets.net/t51bs9y4c70l/2gRBLOYmVdCCUDmJ9pZMza/3a23736732af90a6f2f531e044801744/19_07_30_heights_V4_7724f1_82c468dd-86b5-4265-9af2-f6328496f4c2_2000x__1_.jpg?w=1200',
    socialCard: process.env.NEXT_PUBLIC_SEO_IMG_SOCIAL_CARD ?? '/og-image.jpg',
  },
})
