import {
  backgroundColors,
  foregroundColors,
  SectionFeatureFlags,
} from '@/contentful/utils/commonValues'
import { IconNames } from '@heights/heights-icons'
import { defineDocument, defineObject } from 'sanity-typed-queries'
import { product } from './product'
import { asset } from './asset'
import { ctaAction } from './ctaAction'
import { JsonType } from './JsonType'
import { blockCardItem } from './blockCardItem'
import { mediaPlaybackCta } from './mediaPlaybackCta'
import { productVariant } from './productVariant'
import { subscriptionSection } from './subscriptionSection'
import { textItem } from './textItem'
import { ReferenceType } from './ReferenceType'
import type { ShallowArticleTypeStub } from './article'

export type ShallowSectionTypeStub = {
  _type: 'section'
  _id: string
  _rev: string
  fields: {
    reference?: string
    trackingId?: string
    label?: string
    title?: string
    subtitle?: string
    description?: JsonType
    type?: string
    number?: number
    textlistValue?: string[]
    foregroundColor?: typeof foregroundColors[number]
    backgroundColor?: typeof backgroundColors[number]
    alignment?: 'left' | 'center' | 'right'
    backgroundImage?: ReferenceType | typeof asset.asset
    mobileBackgroundImage?: ReferenceType | typeof asset.asset
    icon?: IconNames
    ctaAction?: ReferenceType | typeof ctaAction.ctaAction
    ctaActions?: ReferenceType[] | typeof ctaAction.ctaAction[]
    enabledFeatureFlags?: SectionFeatureFlags[]
    product?: ReferenceType | typeof product.product
  }
}

export interface SectionTypeStub extends ShallowSectionTypeStub {
  fields: ShallowSectionTypeStub['fields'] & {
    components?:
      | ReferenceType[]
      | (
          | ShallowArticleTypeStub
          // | ShallowSectionTypeStub
          | typeof blockCardItem.blockCardItem
          | typeof blockCardItem.blockCardItem
          // | typeof ctaAction.ctaAction
          | typeof mediaPlaybackCta.mediaPlaybackCta
          // | typeof product.product
          | typeof productVariant.productVariant
          | typeof subscriptionSection.subscriptionSection
          | typeof textItem.textItem
        )[]
  }
}

const sectionFields = defineObject(
  'sectionFields',
  {
    reference: { type: 'string' },
    trackingId: { type: 'string' },
    title: { type: 'string' },
    label: { type: 'string' },
    subtitle: { type: 'string' },
    description: { type: 'json' },
    type: { type: 'string' },
    number: { type: 'number' },
    textlistValue: {
      type: 'array',
      of: [{ type: 'string' }],
    },
    foregroundColor: {
      type: 'string',
      options: { list: foregroundColors },
    },
    backgroundColor: {
      type: 'string',
      options: { list: backgroundColors },
    },
    alignment: {
      type: 'string',
      options: { list: ['left' as const, 'center' as const, 'right' as const] },
    },
    backgroundImage: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    mobileBackgroundImage: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    icon: {
      type: 'string',
      options: { list: Object.values(IconNames) },
    },
    ctaAction: {
      type: 'reference',
      to: [{ type: 'ctaAction' }],
    },
    ctaActions: {
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [{ type: 'ctaAction' }],
        },
      ],
    },
    enabledFeatureFlags: {
      type: 'array',
      of: [
        {
          type: 'string',
          options: { list: Object.values(SectionFeatureFlags) },
        },
      ],
    },
    product: {
      type: 'reference',
      to: [{ type: 'product' }],
    },
    components: {
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [
            { type: 'article' },
            { type: 'blockCardItem' },
            // { type: 'ctaAction' },
            { type: 'mediaPlaybackCta' },
            // { type: 'product' },
            { type: 'productVariant' },
            { type: 'subscriptionSection' },
            { type: 'textItem' },
            { type: 'section' },
          ],
        },
      ],
    },
  },
  [
    {} as JsonType,
    {} as SectionTypeStub,
    {} as ShallowArticleTypeStub,
    asset.asset,
    ctaAction.ctaAction,
    product.product,
    productVariant.productVariant,
    blockCardItem.blockCardItem,
    mediaPlaybackCta.mediaPlaybackCta,
    subscriptionSection.subscriptionSection,
    textItem.textItem,
  ]
)

export const section = defineDocument(
  'section',
  {
    fields: { type: 'sectionFields' },
  },
  [sectionFields.sectionFields]
)
