import compact from 'just-compact'
import { useMemo } from 'react'
import { useGlobalData } from '@/hooks'
import type { ProductModel } from '@/types'
import type { ProductVariantEntry } from './useAllProductVariants'

type SuggestedVariant = NonNullable<ProductModel['suggestedVariants']>[number]

export function useCartSuggestions(
  cartProductVariants: ProductVariantEntry[],
  allProductVariants: ProductVariantEntry[]
) {
  const globalData = useGlobalData()

  const cartProductSuggestionsForSimilarProduct = useMemo(() => {
    const suggestedVariants = Array.from(
      cartProductVariants.reduce<Set<SuggestedVariant>>((acc, variant) => {
        variant.product.suggestedVariants?.forEach((suggestion) =>
          acc.add(suggestion)
        )
        return acc
      }, new Set())
    )?.filter((suggestion) => {
      const alreadyInCart = cartProductVariants.some(
        ({ variant }) => suggestion.variantId === variant.variantId
      )
      return !alreadyInCart
    })

    return compact(
      suggestedVariants
        .filter(
          (variant, index, self) =>
            index === self.findIndex((v) => v.variantId === variant.variantId)
        )
        .map((suggestion) =>
          allProductVariants.find(
            ({ variant }) => suggestion.variantId === variant.variantId
          )
        )
    )
  }, [allProductVariants, cartProductVariants])

  const cartProductSuggestionsForEmptyCart = useMemo<
    ProductVariantEntry[]
  >(() => {
    const suggestedVariants =
      globalData?.cartProductSuggestions?.filter((suggestion) => {
        const alreadyInCart = cartProductVariants.some(
          ({ variant }) => variant.contentfulId === suggestion._id
        )
        return !alreadyInCart
      }) ?? []

    return compact(
      suggestedVariants?.map((suggestion) =>
        allProductVariants.find(
          ({ variant }) => variant.contentfulId === suggestion._id
        )
      )
    )
  }, [allProductVariants, cartProductVariants, globalData])

  const cartProductSuggestions = useMemo(
    () =>
      cartProductVariants?.length
        ? cartProductSuggestionsForSimilarProduct
        : cartProductSuggestionsForEmptyCart,
    [
      cartProductVariants,
      cartProductSuggestionsForSimilarProduct,
      cartProductSuggestionsForEmptyCart,
    ]
  )

  return cartProductSuggestions
}
