import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { SeoData } from '../../constants'
import { useGlobalData, useProduct } from '@/hooks'

type Props = React.PropsWithChildren<{
  title?: string
  description?: string
  lang?: string
  meta?: unknown[]
  keywords?: string[]
  image?: string
  type?: string
  robots?: string
}>

export const SeoFields: React.FC<React.PropsWithChildren<Props>> = ({
  description,
  title,
  image,
  robots,
  type = 'website',
  children,
}) => {
  const router = useRouter()
  const globalData = useGlobalData()
  const reviewProduct = useProduct(
    globalData?.fields?.reviewSnippetProduct ?? -1
  )

  const {
    title: siteTitle,
    defaultTitle,
    description: defaultDescription,
    siteUrl,
    images,
    social,
  } = SeoData

  const seo = {
    mainTitle: title || defaultTitle,
    title: `${title || defaultTitle} | ${siteTitle}`,
    description: description || defaultDescription,
    image: image || images.socialCard || images.heroCloud,
    url: `${siteUrl}${router?.pathname}`,
  }

  const safeImage =
    seo.image && seo.image.startsWith('//') ? `https:${seo.image}` : seo.image

  return (
    <Head>
      <title>{seo.mainTitle}</title>
      <meta name="description" content={seo.description ?? ''} />
      <meta name="image" content={safeImage} />
      <meta name="robots" content={robots} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      <meta property="og:type" content={type} />
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {safeImage && <meta property="og:image" content={safeImage} />}
      <meta name="twitter:card" content="summary_large_image" />
      {social?.twitter && (
        <meta name="twitter:creator" content={social?.twitter} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {safeImage && <meta name="twitter:image" content={safeImage} />}
      {!!reviewProduct && (
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'Product',
            brand: {
              '@type': 'Brand',
              name: 'Heights',
            },
            description:
              globalData?.fields?.reviewSnippetDescription ?? seo.description,
            sku: globalData?.fields?.reviewSnippetProduct,
            image:
              reviewProduct.productImage && 'src' in reviewProduct.productImage
                ? `${siteUrl}${reviewProduct.productImage?.src}`
                : undefined,
            name: reviewProduct.name,
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: globalData?.fields?.trustpilotScore,
              ratingCount: globalData?.fields?.trustpilotRatingCount,
            },
          })}
        </script>
      )}
      {children}
    </Head>
  )
}
