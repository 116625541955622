import { setCookie } from 'nookies'

const FAR_FUTURE_DAYS = 6000
export const dropSubscribeCookie = (
  state: 'seen' | 'subscribed',
  expirationInDays: number = FAR_FUTURE_DAYS,
  cookieName = 'heights-subscribe-modal'
) => {
  setCookie(null, cookieName, state, {
    maxAge: expirationInDays * 24 * 3600,
    sameSite: 'strict',
  })
}
