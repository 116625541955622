import React, { useContext, useEffect, useMemo } from 'react'
import { CartContext } from '@/context'
import { useProducts } from '@/hooks/useProducts'
import {
  GenericCart,
  Slideout,
  DiscountCodeInputStatus,
  CallToAction,
  Text,
} from '@heights/heights-ui-library'
import {
  decodeShopifyId,
  ecommerceInfoFromLineItems,
  shopifyIdsMatch,
} from '@/utils/shopify'
import { useData, useDataLayer } from '@/hooks'
import pluralize from 'pluralize'
import { trackingIdFactory } from '@heights/heights-utils'
import compact from 'just-compact'
import { roundToPrecision } from '@/utils/math'
import {
  ProductVariantEntry,
  useAllProductVariants,
} from './useAllProductVariants'
import { useDiscountOptions } from './useDiscountOptions'
import { useCartActions } from './useCartActions'
import { useCartUsps } from './useCartUsps'
import { useCartSelect } from './useCartSelect'
import { useCartBanner } from './useCartBanner'
import { DynamicIconPicker } from '@heights/heights-icons'
import { CurrencyContext, useIntl } from '@heights/heights-context'
import { LocaleCodes } from '@/constants'
import { shopifyDiscountCode } from '@/schemas/shopify'
import { DataSourceType } from '@/hooks/useData'
import useTranslation from 'next-translate/useTranslation'
import { useCartSuggestions } from './useCartSuggestions'
import { cartOpenSignal, closeCart } from '@/signals'
import { effect } from '@preact/signals-react'

const [query, type] = shopifyDiscountCode.builder
  .filter('type == "autobundle"')
  // @README we're just going to exclude these for now as we are switching to the new bundle set-up
  // leaving it here in case something goes wrong in the near future and we decide to rollback
  .select(0, 0, true)
  .use()

export const Cart: React.FC = () => {
  const { t } = useTranslation('cart')
  const cartContext = useContext(CartContext)
  const currencyContext = useContext(CurrencyContext)
  const products = useProducts()
  const [pushEvent] = useDataLayer()
  const { locale } = useIntl()
  const discountReader = useData<typeof type>(DataSourceType.SHOPIFY, query)
  const autobundleDiscountCodes = discountReader()
  const taxNotice = t('tax-notice')
  const isNorthAmerica =
    locale === LocaleCodes.EN_US || locale === LocaleCodes.EN_CA
  const actualCurrencyCode = cartContext.storefront.cart?.lines.edges.length
    ? cartContext.storefront.cart?.estimatedCost.subtotalAmount.currencyCode ||
      currencyContext.currency.currencyCode
    : currencyContext.currency.currencyCode

  const allProductVariants = useAllProductVariants(products)

  const [hasValidDiscountCode, canCheckDiscountCodeStatus, discountCode] =
    useDiscountOptions(cartContext.storefront.cart)
  const cartActions = useCartActions()

  const cartProductVariants = useMemo<ProductVariantEntry[]>(() => {
    const edges = cartContext.storefront.cart?.lines.edges ?? []
    return compact(
      edges.map((edge) => {
        const productVariant = allProductVariants.find(({ variant }) =>
          shopifyIdsMatch(variant.variantId, edge.node.merchandise.id)
        )
        if (productVariant) {
          return {
            ...productVariant,
            line: edge.node,
          }
        }
        return null
      })
    )
  }, [cartContext, allProductVariants])

  const cartProductSuggestions = useCartSuggestions(
    cartProductVariants,
    allProductVariants
  )

  const cartItems =
    cartContext.storefront.cart?.lines.edges.reduce(
      (previous, current) => previous + current.node.quantity,
      0
    ) ?? 0

  const automaticSavings = useMemo(() => {
    return cartProductVariants
      .map((variant) => {
        const estimatedCost = variant.line?.estimatedCost
        const subtotal = parseFloat(estimatedCost?.subtotalAmount.amount ?? '0')
        const total = parseFloat(estimatedCost?.totalAmount.amount ?? '0')
        return roundToPrecision(subtotal - total, 2)
      })
      .reduce((total, value) => total + value, 0)
  }, [cartProductVariants])

  const cartUsps = useCartUsps()
  const cartBanner = useCartBanner(cartProductVariants)
  const cartSelect = useCartSelect(actualCurrencyCode)

  effect(() => {
    if (cartOpenSignal.value) {
      const products = ecommerceInfoFromLineItems(
        cartContext.storefront.cart?.lines.edges ?? []
      )
      // @TODO move to datahappy?
      pushEvent({
        event: 'dl_view_cart',
        value: products.reduce((total, product) => total + product.price, 0),
        ecommerce: {
          currencyCode: actualCurrencyCode,
          detail: { products },
        },
      })
    }
  })

  useEffect(() => {
    const lineVariants = compact(
      cartContext.storefront.cart?.lines.edges.map((edge) =>
        decodeShopifyId(edge.node.merchandise.id)
      ) ?? []
    )
    const allAvailableBundleCodes = autobundleDiscountCodes?.reduce<string[]>(
      (acc, discount) => acc.concat(compact(discount.codes ?? [])),
      []
    )
    const sortedAutobundleDiscountCodes =
      autobundleDiscountCodes?.sort((a, b) => {
        const aFlagCount = a.flags?.length ?? 0
        const bFlagCount = b.flags?.length ?? 0
        if (aFlagCount > bFlagCount) return -1
        if (aFlagCount < bFlagCount) return 1
        return 0
      }) ?? []
    const autobundleMatch =
      sortedAutobundleDiscountCodes?.find((discount) => {
        const localeFlag =
          discount.flags?.find((flag) => flag.type === 'locale') ?? null
        const currencyFlag =
          discount.flags?.find((flag) => flag.type === 'currency') ?? null

        const localeMatch =
          !localeFlag?.value ||
          localeFlag.value.toLowerCase() === locale.toLowerCase()

        const currencyMatch =
          !currencyFlag?.value ||
          currencyFlag.value.toLowerCase() ===
            currencyContext.currency.currencyCode.toLowerCase()

        return (
          (!discount.flags?.length || (localeMatch && currencyMatch)) &&
          discount.products?.length &&
          discount.products.every((product) => {
            return (
              product.productVariants?.length &&
              product.productVariants.some((variantId) =>
                lineVariants.includes(decodeShopifyId(variantId))
              )
            )
          })
        )
      }) ?? null
    const currentlyAppliedDiscountCodes = compact(
      cartContext.storefront.cart?.discountCodes.map((code) => code.code) ?? []
    )
    if (
      autobundleMatch &&
      currentlyAppliedDiscountCodes?.[0] !== autobundleMatch.codes?.[0]
    ) {
      const code = autobundleMatch.codes?.[0]
      if (code) {
        cartActions.applyDiscountCode(code)
      }
    } else if (
      !autobundleMatch &&
      currentlyAppliedDiscountCodes[0] &&
      allAvailableBundleCodes?.includes(currentlyAppliedDiscountCodes[0])
    ) {
      cartActions.applyDiscountCode('')
    }
  }, [locale, cartContext.storefront.cart, autobundleDiscountCodes])

  return (
    <CurrencyContext.Provider
      value={{
        ...currencyContext,
        currency: {
          ...currencyContext.currency,
          currencyCode: actualCurrencyCode,
        },
      }}
    >
      <Slideout
        open={cartOpenSignal.value}
        onClose={closeCart}
        title="Your cart"
        extra={
          <div className="font-normal">
            {`${cartItems} ${pluralize('item', cartItems)}`}
          </div>
        }
        bannerTitle={cartBanner}
      >
        <GenericCart
          items={cartProductVariants}
          suggestedItems={cartProductSuggestions ?? []}
          select={cartSelect}
          discountCode={
            discountCode ?? (automaticSavings ? 'BRAINCAREBUNDLE' : undefined)
          }
          shippingCost={isNorthAmerica ? 0 : null}
          freeLabel="FREE"
          totalLabel={
            locale === LocaleCodes.EN_US ? 'Estimated total' : 'Total'
          }
          discountCodeStatus={
            hasValidDiscountCode || automaticSavings
              ? DiscountCodeInputStatus.SUCCESS
              : canCheckDiscountCodeStatus
              ? DiscountCodeInputStatus.ERROR
              : DiscountCodeInputStatus.UNKNOWN
          }
          discountCodeMessage={
            canCheckDiscountCodeStatus && !hasValidDiscountCode
              ? 'This discount code could not be applied.'
              : hasValidDiscountCode || automaticSavings
              ? 'Discount successfully applied.'
              : undefined
          }
          discountAmount={(subtotal: number) => {
            if (automaticSavings) {
              return automaticSavings
            }

            const subtotalCost = parseFloat(
              cartContext.storefront.cart?.estimatedCost?.subtotalAmount
                ?.amount ?? '0'
            )
            if (subtotalCost) {
              const totalCost = parseFloat(
                cartContext.storefront.cart?.estimatedCost?.totalAmount
                  ?.amount ?? '0'
              )
              const discountPercentage =
                (subtotalCost - totalCost) / subtotalCost
              return subtotal * discountPercentage
            }
            return 0
          }}
          summaryConfig={compact([
            'subtotal',
            'discount',
            locale === LocaleCodes.EN_CA && (
              <>
                <div>
                  {'Tax '}
                  <span className="font-normal">(calculated at checkout)</span>
                </div>
                <div>--</div>
              </>
            ),
            'shipping',
            taxNotice ? (
              <Text
                key="notice"
                variant="noteSmallShort"
                className="text-neutral-700 mt-2"
              >
                {taxNotice}
              </Text>
            ) : null,
          ])}
          checkoutButton={
            <CallToAction
              as="button"
              data-track-id={trackingIdFactory({
                component: 'cart',
                action: 'checkout',
              })}
              disabled={!cartContext.storefront.cart?.lines?.edges.length}
              className="w-full items-center"
              cta={{
                style: 'primary',
                onClick: cartActions.checkout,
              }}
            >
              <DynamicIconPicker icon="lock" size={13} />
              <p className="h-[0.625rem] ml-2 my-0">Checkout Securely</p>
            </CallToAction>
          }
          onAddSuggestedProduct={cartActions.addSuggestedProduct}
          onRemoveProduct={cartActions.removeProduct}
          onChangeProductQuantity={cartActions.changeQuantity}
          onApplyDiscountCode={cartActions.applyDiscountCode}
          onRemoveDiscountCode={cartActions.removeDiscountCode}
          onCheckout={cartActions.checkout}
          ingredientUspItems={cartUsps}
          onClose={closeCart}
        />
      </Slideout>
    </CurrencyContext.Provider>
  )
}
