/* eslint "no-async-promise-executor": "off" */
import z from 'zod'
import { decode } from 'js-base64'
import { Response } from '@/pages/api/klaviyo/profile/exchange'
import { createCookiesSignal } from './createCookiesSignal'
import { effect, signal } from '@preact/signals-react'

type Identity = {
  id?: string
  $email?: string
  $phone_number?: string
}

const cookiesSignal = createCookiesSignal()
const klaviyoIdentityPromise = signal<Promise<Identity | null> | null>(null)
const klaviyoIdentitySignal = signal<Identity | null>(null)

effect(() => {
  const klaId = cookiesSignal.value['__kla_id']
  if (!klaId) return

  const decoded = decode(klaId)
  const parsed: unknown = JSON.parse(decoded)
  const schemaResult = z.object({ $exchange_id: z.string() }).safeParse(parsed)
  if (!schemaResult.success) return

  const nextPromise = new Promise<Identity | null>(async (resolve) => {
    // start by waiting out the current promise
    await klaviyoIdentityPromise.peek()
    // continue to update identity
    const response = await fetch('/api/klaviyo/profile/exchange', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ exchangeId: schemaResult.data.$exchange_id }),
    })
    const result = (await response.json()) as Response
    if (result.success) {
      klaviyoIdentitySignal.value = result.object
    }

    resolve(klaviyoIdentitySignal.value)
  })
  klaviyoIdentityPromise.value = nextPromise
})

const signals = [klaviyoIdentitySignal, klaviyoIdentityPromise] as const

export function createKlaviyoIdentitySignals() {
  return signals
}
