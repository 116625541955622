import type { ShallowMenuItemType } from '@/hooks/useMenu'

export function contentfulLinkAsUrl(link: ShallowMenuItemType['link']) {
  if (link.page && link.page.urlHandle) {
    return `/${link.page.urlHandle}/`
  }

  if (link.fields?.url) {
    return link.fields?.url
  }

  return '/'
}
