import compact from 'just-compact'
import groq from 'groq'
import { useMemo } from 'react'
import type { SingleComponentPickerItem } from '@/components/single-component-picker'
import type { backgroundColors } from '@/contentful/utils/commonValues'
import type { ReferenceType } from '@/schemas/ReferenceType'
import { DataSourceType, useData } from './useData'

const fragment = groq`
  _id,
  _type,
  "type": fields.type,
  fields {
    reference,
    trackingId,
    backgroundColor
  }
`

export type ShallowPickerDataRootEntry = Pick<
  SingleComponentPickerItem,
  '_id' | '_type' | 'type'
> & {
  fields: {
    reference?: string
    trackingId?: string
    backgroundColor?: (typeof backgroundColors)[number]
  }
}

export type ShallowPickerDataEntry =
  | (ShallowPickerDataRootEntry & {
      experiment?: {
        experimentId: string
        controlItems?: ShallowPickerDataRootEntry[]
        variantItems?: ShallowPickerDataRootEntry[]
      } | null
    })
  | null

export function useShallowPickerData(
  data: (ReferenceType | Pick<SingleComponentPickerItem, '_id'>)[]
) {
  const refs = useMemo(
    () =>
      compact(
        data.map((entry) => ({
          _ref: '_id' in entry ? entry._id : entry._ref,
        })) ?? []
      ),
    [data]
  )

  const reader = useData<ShallowPickerDataEntry[]>(
    DataSourceType.CONTENTFUL,
    groq`$refs[]->{
      ${fragment},
      "experiment": select(
        (_type == "experimentContainer" && defined(fields.experimentId)) => {
          "experimentId": fields.experimentId,
          "controlItems": fields.controlItems[]->{ ${fragment} },
          "variantItems": fields.variantItems[]->{ ${fragment} },
        }
      )
    }`,
    { refs }
  )
  const result = reader()

  return result
}
