import React, { useCallback, useEffect, useState } from 'react'
import { SingleExperimentHandler } from './SingleExperimentHandler'

type Props = {
  ids: string[]
  onUpdated: (experiments: Record<string, boolean>) => void
}

export function ComponentPickerExperiments({ ids, onUpdated }: Props) {
  const [experiments, setExperiments] = useState<Record<string, boolean>>({})

  const handleStatusReceived = useCallback(
    (id: string, status: boolean) => {
      experiments[id] = status
      setExperiments({ ...experiments })
    },
    [experiments]
  )

  useEffect(() => {
    onUpdated(experiments)
  }, [experiments, onUpdated])

  return (
    <>
      {ids.map((id) => (
        <SingleExperimentHandler
          key={id}
          id={id}
          onStatusReceived={handleStatusReceived}
        />
      ))}
    </>
  )
}
