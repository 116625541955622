import { useGlobalData } from '@/hooks'
import compact from 'just-compact'
import { useMemo } from 'react'

export function useCartUsps() {
  const globalData = useGlobalData()

  const cartUsps = useMemo(
    () =>
      compact(
        globalData?.cartUsps?.map((usp) =>
          usp.icon
            ? {
                title: usp.title ?? '',
                icon: usp.icon,
              }
            : null
        ) ?? []
      ),
    [globalData?.cartUsps]
  )

  return cartUsps
}
