import { defineDocument, defineObject } from 'sanity-typed-queries'

const shopifyDiscountCodeFlag = defineObject('shopifyDiscountCodeFlag', {
  type: {
    type: 'string',
    options: { list: ['locale'] },
  },
  value: {
    type: 'string',
  },
})

const shopifyDiscountCodeProduct = defineObject('shopifyDiscountCodeProduct', {
  productId: { type: 'number' },
  productVariants: {
    type: 'array',
    of: [{ type: 'string' }],
  },
})

export const shopifyDiscountCode = defineDocument(
  'shopifyDiscountCode',
  {
    type: {
      type: 'string',
      options: {
        list: ['autobundle'],
      },
    },
    codes: { type: 'array', of: [{ type: 'string' }] },
    products: {
      type: 'array',
      of: [{ type: 'shopifyDiscountCodeProduct' }],
    },
    flags: {
      type: 'array',
      of: [{ type: 'shopifyDiscountCodeFlag' }],
    },
  },
  [
    shopifyDiscountCodeProduct.shopifyDiscountCodeProduct,
    shopifyDiscountCodeFlag.shopifyDiscountCodeFlag,
  ]
)
