import { defineDocument, defineObject } from 'sanity-typed-queries'
import { supplementFacts } from './supplementFacts'
import { subscriptionSection } from './subscriptionSection'
import type { SectionTypeStub } from './section'

const modalFields = defineObject(
  'modalFields',
  {
    reference: { type: 'string' },
    trackingId: { type: 'string' },
    modalVariant: { type: 'string' },
    modalSize: {
      type: 'string',
      options: {
        list: ['default' as const, 'small' as const, 'xsmall' as const],
      },
    },
    components: {
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [{ type: 'section' }, { type: 'subscriptionSection' }],
        },
      ],
    },
    linkedContent: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'nutrientList' }] }],
    },
    autoOpen: {
      type: 'boolean',
    },
    autoOpenCookiename: {
      type: 'string',
    },
    autoOpenDelay: {
      type: 'number',
    },
    autoOpenExclusions: {
      type: 'array',
      of: [{ type: 'string' }],
    },
    autoOpenInclusions: {
      type: 'array',
      of: [{ type: 'string' }],
    },
  },
  [
    {} as SectionTypeStub,
    supplementFacts.nutrientList,
    subscriptionSection.subscriptionSection,
  ]
)

export const modal = defineDocument(
  'modal',
  {
    fields: { type: 'modalFields' },
  },
  [modalFields.modalFields]
)
