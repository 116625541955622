import React from 'react'
import compact from 'just-compact'
import type { MenuType, ShallowSubmenuType } from './useMenu'
import { getOrAssignABSplit } from './useABSplit'

export function useMenuExperiments(menu?: MenuType | ShallowSubmenuType) {
  const [experimentValues, setExperimentValues] = React.useState<
    Record<string, boolean>
  >({})
  const experimentIds = React.useMemo(() => {
    const extractFromArray = (
      menus: (MenuType | ShallowSubmenuType)[]
    ): string[] => {
      return menus.reduce<string[]>((acc, item) => {
        let next = [...acc]
        item?.menuItems?.forEach((menuItem) => {
          if (menuItem?.experimentVariation?.fields?.experimentId)
            next.push(menuItem.experimentVariation.fields.experimentId)
        })
        next = next.concat(
          extractFromArray(
            compact(item && 'submenus' in item ? item.submenus ?? [] : [])
          )
        )
        return next
      }, [])
    }
    return Array.from(new Set([...extractFromArray(menu ? [menu] : [])]))
  }, [menu])

  React.useEffect(() => {
    const experiments: Record<string, boolean> = {}

    if (typeof window !== 'undefined') {
      experimentIds.forEach((experimentId) => {
        const variant = getOrAssignABSplit(experimentId)
        experiments[experimentId] = !!(experimentId && variant === 1)
        setExperimentValues(experiments)
      })
    }
  }, [experimentIds])
  return experimentValues
}
