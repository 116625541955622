import { PersistedQueryKeys } from '@/constants'
import { createPersistedQueryParamsSignal } from '@/signals'
import { nanoid } from 'nanoid'

export type DataLayerEcommerceData = {
  currencyCode?: string
  value?: number
  detail?: {
    products?: {
      productId?: string | number
      variantId?: string | number
      name?: string
      price?: number
      quantity?: number
    }[]
  }
}

export type DataLayerEvent = {
  [field: string]: unknown
  event: string
  ecommerce?: DataLayerEcommerceData
}

const queryParamsSignal = createPersistedQueryParamsSignal(
  ...PersistedQueryKeys
)

const pushEvent = (incoming: DataLayerEvent) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    ...incoming,
    'x-fb-ck-fbc': queryParamsSignal.value.fbclid,
    event_id: `${incoming.event}_${nanoid()}`,
  })
}

const useDataLayerValue = [pushEvent]

export const useDataLayer = () => {
  return useDataLayerValue
}
