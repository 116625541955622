import { signal } from '@preact/signals-react'

export const cartOpenSignal = signal(false)

export const openCart = () => {
  cartOpenSignal.value = true
}

export const closeCart = () => {
  cartOpenSignal.value = false
}
