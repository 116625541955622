import { defineDocument, defineObject } from 'sanity-typed-queries'
import { asset } from './asset'

const mediaPlaybackCtaFields = defineObject(
  'mediaPlaybackCtaFields',
  {
    reference: { type: 'string' },
    trackingId: { type: 'string' },
    title: { type: 'string' },
    mediaPath: { type: 'string' },
    description: { type: 'string' },
    image: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    border: { type: 'boolean' },
    variant: {
      type: 'string',
      options: {
        list: [
          'vertical' as const,
          'horizontal' as const,
          'fullwidth' as const,
        ],
      },
    },
  },
  [asset.asset]
)

export const mediaPlaybackCta = defineDocument(
  'mediaPlaybackCta',
  {
    fields: {
      type: 'mediaPlaybackCtaFields',
    },
  },
  [mediaPlaybackCtaFields.mediaPlaybackCtaFields]
)
