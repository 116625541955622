import { useState, useCallback, useMemo, useEffect } from 'react'

type PromiseFunction<Args extends unknown[], Result> = (
  ...args: Args
) => Promise<Result>

export enum PromiseStatus {
  IDLE,
  PENDING,
  DONE,
  ERROR,
}

export function usePromiseLoader<Args extends unknown[], Result>(
  promiseFunction: PromiseFunction<Args, Result>
) {
  const [lastError, setLastError] = useState<Error | null>(null)
  const [status, setStatus] = useState(PromiseStatus.IDLE)

  const wrapped = useCallback<PromiseFunction<Args, Result | null>>(
    async (...args) => {
      try {
        setStatus(PromiseStatus.PENDING)
        const result = await promiseFunction(...args)
        setStatus(PromiseStatus.DONE)
        return result
      } catch (err) {
        console.error(err)
        // Sentry.captureException(err)
        setStatus(PromiseStatus.ERROR)
        if (err instanceof Error) {
          setLastError(err)
        }
      }
      return null
    },
    [promiseFunction]
  )

  useEffect(() => {
    const handlePageShow = () => {
      // @REAMDE this is necessary because otherwise the state is not reset
      // when navigating back using history traversal - since React will only be rehydrated
      // but no reset
      setStatus(PromiseStatus.IDLE)
    }
    window.addEventListener('pageshow', handlePageShow)
    return () => window.removeEventListener('pageshow', handlePageShow)
  }, [])

  return useMemo(
    () => ({
      error: lastError,
      load: wrapped,
      status: status,
    }),
    [wrapped, status, lastError]
  )
}
