export function groupSiblingsBy<V, R>(arr: V[], fn: (item: V) => R): V[][] {
  let currentGroup: V[] = []
  const allGroups: V[][] = []
  arr.reduce<null | R>((prevResult, item, index) => {
    const result = fn(item)
    if (currentGroup.length === 0 || prevResult === result) {
      currentGroup.push(item)
    } else {
      allGroups.push(currentGroup)
      currentGroup = [item]
    }
    if (index === arr.length - 1) {
      allGroups.push(currentGroup)
    }
    return result
  }, null)
  return allGroups
}
