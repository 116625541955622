import { useMemo } from 'react'
import type { CartFieldsFragmentFragment } from '@/graphql/storefront'
import type { ProductModel, ProductVariantModel } from '@/types'

export type ProductVariantEntry = {
  product: ProductModel
  variant: ProductVariantModel
  line?: CartFieldsFragmentFragment['lines']['edges'][number]['node'] | null
}

export function useAllProductVariants(products: Record<string, ProductModel>) {
  const allProductVariants = useMemo(
    () =>
      Object.values(products).reduce<ProductVariantEntry[]>((acc, product) => {
        return acc.concat(
          product.variants.map((variant) => ({
            variant,
            product,
          }))
        )
      }, []),
    [products]
  )
  return allProductVariants
}
