import { ctaAction } from '@/schemas'
import { asset } from '@/schemas/asset'
import { experimentVariation } from '@/schemas/experimentVariation'
import { link } from '@/schemas/link'
import { menu } from '@/schemas/menu'
import { menuItem } from '@/schemas/menuItem'
import { MenuLocation } from '../constants'
import { DataSourceType, useData } from './useData'

export const shallowMenuItemFieldsFragment = `
  ...,
  "image": fields.image->{...},
  "link": fields.link->{
    ...,
    "page": fields.page->{
      _id,
      "urlHandle": fields.urlHandle
    }
  },
  "experimentVariation": fields.experimentVariation->{
    ...
  }
`

export const shallowSubmenuFieldsFragment = `
  ...,
  "menuItems": fields.menuItems[]->{
    ${shallowMenuItemFieldsFragment}
  }
`

export const menuQuery = `*[_type == "menu" && fields.location == $location][0] {
  ...,
  "menuItems": fields.menuItems[]->{
    ${shallowMenuItemFieldsFragment},
    "submenus": fields.submenus[]->{
      ${shallowSubmenuFieldsFragment}
    }
  },
  "submenus": fields.submenus[]->{
    ${shallowSubmenuFieldsFragment}
  },
  "ctaAction": fields.ctaAction->{
    ...
  }
}`

export type ShallowMenuItemType = typeof menuItem.menuItem & {
  image: typeof asset.asset
  link: typeof link.link & {
    page: {
      _id: string
      urlHandle: string
    } | null
  }
  experimentVariation: typeof experimentVariation.experimentVariation
}

export type ShallowSubmenuType = typeof menu.menu & {
  menuItems: ShallowMenuItemType[] | null
}

export type MenuItemWithSubmenuType = ShallowMenuItemType & {
  submenus?: ShallowSubmenuType[]
}

export type MenuType =
  | null
  | (typeof menu.menu & {
      menuItems: (ShallowMenuItemType & {
        submenus: ShallowSubmenuType[]
      })[]
      submenus: ShallowSubmenuType[]
      ctaAction?: Pick<typeof ctaAction.ctaAction, 'fields'>
    })

export const useMenu = (location: MenuLocation) => {
  const reader = useData<MenuType>(DataSourceType.CONTENTFUL, menuQuery, {
    location,
  })
  return reader()
}
