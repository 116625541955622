import React, { useMemo } from 'react'
import compact from 'just-compact'
import { Footer } from '@heights/heights-ui-components'
import { Locales, MenuLocation, SocialData } from '../../constants'
import { useGlobalData, useMenu, useMenuExperiments } from '@/hooks'
import {
  contentfulMenuItemAsNavData,
  filterMenuItemsByExperiments,
} from '@/utils/contentful'
import { ComponentPicker } from '../ComponentPicker'
import { RegionIcon } from '@heights/heights-ui-library'

export const LayoutFooter: React.FC = () => {
  const globalData = useGlobalData()
  const footerMenu = useMenu(MenuLocation.FOOTER)
  const footerLegal = useMenu(MenuLocation.FOOTER_LEGAL)
  const footerMenuExperiments = useMenuExperiments(footerMenu)
  const footerLegalExperiments = useMenuExperiments(footerLegal)

  // @TODO - update with new banner?
  const footerSubscribeFormContent = compact(
    globalData?.fields?.footerSubscribeFormContent ?? []
  )

  const footerMenuLists = useMemo(
    () =>
      compact(
        compact(footerMenu?.submenus ?? []).map((submenu) => ({
          title: submenu?.fields?.title ?? '',
          menuItems: filterMenuItemsByExperiments(
            compact((submenu?.menuItems ?? []).map((menuItem) => menuItem)),
            footerMenuExperiments
          ).map((menuItem) => contentfulMenuItemAsNavData(menuItem)),
        })) ?? []
      ),
    [footerMenu, footerMenuExperiments]
  )
  const footerLegalMenuItems = useMemo(() => {
    return (
      filterMenuItemsByExperiments(
        compact((footerLegal?.menuItems ?? []).map((menuItem) => menuItem)),
        footerLegalExperiments
      )?.map((menuItem) => contentfulMenuItemAsNavData(menuItem)) ?? []
    )
  }, [footerLegal, footerLegalExperiments])

  return (
    <>
      {footerSubscribeFormContent && (
        <ComponentPicker data={footerSubscribeFormContent} />
      )}
      <Footer
        className="pb-8 md:pb-9"
        logoLink={{
          url: '/',
          label: 'Heights',
        }}
        bcorporationLink={{
          url: 'https://bcorporation.net',
          label: 'Certified B Corporation',
        }}
        locales={Locales}
        menuLists={footerMenuLists}
        legalMenuItems={footerLegalMenuItems}
        socialMenuItems={[
          { icon: 'facebook', url: SocialData.facebook, openInNewTab: true },
          { icon: 'instagram', url: SocialData.instagram, openInNewTab: true },
          { icon: 'twitter', url: SocialData.twitter, openInNewTab: true },
          { icon: 'youtube', url: SocialData.youtube, openInNewTab: true },
        ]}
        legalCopy={{
          generalStatement:
            'This product is not designed to replace a varied and balanced diet. Do not exceed stated dose. If you are pregnant, breastfeeding or taking any medication, please consult your doctor before use. Do not use it if the seal has been tampered with. Store in a cool, dry place. Keep out of reach of children.',
          disclaimerBox:
            '* These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure or prevent any disease',
        }}
        renderLocaleIcon={(countryCode) =>
          countryCode === 'CA' ? (
            <svg
              width="432"
              height="431"
              viewBox="0 0 432 431"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4"
            >
              <path
                d="M89.5138 216.467L75.62 221.23L140.524 278.281C144.482 289.995 141.051 295.08 137.334 300.586C136.392 301.982 135.432 303.405 134.569 304.97L205.03 296.041L203.244 366.982L217.931 366.486L214.656 296.14L285.316 304.474C284.811 303.407 284.312 302.397 283.832 301.425C280.159 293.99 277.538 288.686 281.049 275.701L345.952 221.627L334.639 217.559C328.42 212.73 332.257 198.994 336.094 185.257L336.095 185.255L336.095 185.254C338.014 178.387 339.932 171.52 340.593 165.766C340.593 165.766 302.782 178.764 300.301 171.918L290.476 153.562L256.139 191.266C252.368 192.258 250.78 190.67 249.887 187.495L265.766 108.418L240.558 122.606C238.474 123.499 236.39 122.606 235.001 120.225L210.687 71.6078L185.678 122.11C183.792 123.896 181.907 124.095 180.319 122.904L156.303 109.41L170.693 187.991C169.601 190.968 166.822 191.96 163.647 190.273L130.599 152.769C129.191 155.061 127.923 157.828 126.653 160.601C124.075 166.229 121.488 171.876 117.698 173.605C113.819 175.17 103.592 172.302 93.4189 169.449C88.7579 168.142 84.1084 166.838 80.0859 165.965C81.21 170.026 82.9523 174.778 84.7727 179.742C90.0239 194.063 95.925 210.156 89.5138 216.566V216.467Z"
                fill="#D52B1E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M203.728 0.341919C90.1444 6.70343 0 100.825 0 216C0 259.984 13.1468 300.898 35.7252 335.026V334.251C74.3834 392.549 140.691 431 216 431C290.664 431 356.479 393.205 395.275 335.745V336.524C418.465 302.098 432 260.63 432 216C432 101.008 342.142 7.00238 228.812 0.372864C224.573 0.125427 220.301 0 216 0C211.882 0 207.79 0.11499 203.728 0.341919ZM35.7252 138.65V292.35C65.6514 362.101 135.079 411 216 411C296.032 411 364.823 363.169 395.275 294.64V136.36C364.823 67.8309 296.032 20 216 20C135.079 20 65.6514 68.8988 35.7252 138.65Z"
                fill="#D52B1E"
              />
            </svg>
          ) : (
            <RegionIcon region={countryCode} />
          )
        }
      />
    </>
  )
}
