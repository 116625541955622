import { useGlobalData } from '@/hooks'
import compact from 'just-compact'
import { useMemo } from 'react'
import { ProductVariantEntry } from './useAllProductVariants'

export function useCartBanner(cartProductVariants: ProductVariantEntry[]) {
  const globalData = useGlobalData()

  const banner = useMemo(() => {
    const variantsInCart = compact(
      cartProductVariants.map((entry) => entry.variant.contentfulId)
    )

    let bannerContent =
      variantsInCart.length === 0 ? globalData?.fields?.cartUpsellBanner : ''

    cartProductVariants.some((entry) => {
      return (
        entry.product.dynamicBanners?.some((banner) => {
          const hideIfAllMatch =
            banner.hideIfAllPresent?.every(
              (ref) => ref._ref && variantsInCart.includes(ref._ref)
            ) ?? false
          const hideIfAnyMatch =
            banner.hideIfAnyPresent?.some(
              (ref) => ref._ref && variantsInCart.includes(ref._ref)
            ) ?? false
          const showIfAllMatch = banner.showIfAllPresent?.every(
            (ref) => ref._ref && variantsInCart.includes(ref._ref)
          )
          const showIfAnyMatch = banner.showIfAnyPresent?.some(
            (ref) => ref._ref && variantsInCart.includes(ref._ref)
          )

          const shouldShow =
            !(hideIfAllMatch || hideIfAnyMatch) &&
            ((typeof showIfAllMatch === 'undefined' &&
              typeof showIfAnyMatch === 'undefined') ||
              showIfAllMatch ||
              showIfAnyMatch)

          if (shouldShow && banner) {
            bannerContent = banner.content
            return true
          }

          return false
        }) ?? false
      )
    })
    return bannerContent
  }, [globalData, cartProductVariants])

  return banner
}
