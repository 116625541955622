import React, { FC, PropsWithChildren } from 'react'
import { Box } from 'theme-ui'

type Props = PropsWithChildren<{
  id?: string
  contentfulId?: string
}>

export const TrackingContainer: FC<Props> = ({
  id,
  contentfulId,
  children,
}) => {
  if (id || contentfulId) {
    return (
      <Box id={id} data-track-id={id} data-contentful-id={contentfulId}>
        {children}
      </Box>
    )
  }

  return <>{children}</>
}
