import qs from 'query-string'
import omit from 'just-omit'
import { PersistedQueryKeys } from '@/constants'
import { CartContext } from '@/context'
import { useDatahappy, useCurrency, useDataLayer, useProducts } from '@/hooks'
import { useContext, useCallback, useMemo } from 'react'
import {
  decodeShopifyId,
  ecommerceInfoFromLineItems,
  shopifyIdsMatch,
} from '@/utils/shopify'
import type { ProductVariantEntry } from './useAllProductVariants'
import {
  createKlaviyoIdentitySignals,
  createPersistedQueryParamsSignal,
} from '@/signals'

const [klaviyoIdentitySignal, klaviyoIdentityPromiseSignal] =
  createKlaviyoIdentitySignals()
const queryParamsSignal = createPersistedQueryParamsSignal(
  ...PersistedQueryKeys
)

export function useCartActions() {
  const [datahappy] = useDatahappy()
  const [pushEvent] = useDataLayer()
  const { currencyCode } = useCurrency()
  const products = useProducts()
  const cartContext = useContext(CartContext)

  const addSuggestedProduct = useCallback(
    async ({ product, variant }: ProductVariantEntry) => {
      const updatedCart = await cartContext.storefront.addProducts([
        {
          productId: product.productId,
          variantId: variant.variantId,
          quantity: 1,
        },
      ])
      const updatedLine =
        updatedCart?.lines.edges.find((item) =>
          shopifyIdsMatch(item.node.merchandise.id, variant.variantId)
        ) ?? null
      if (updatedLine) {
        ecommerceInfoFromLineItems([updatedLine], products).forEach(
          (product) => {
            const ecommerceInfo = {
              currencyCode,
              value: product.price,
              detail: {
                products: [product],
              },
            }

            datahappy.trackAddToCart(ecommerceInfo, {
              userTraits: klaviyoIdentitySignal.value
                ? {
                    email: klaviyoIdentitySignal.value.$email,
                    phone: klaviyoIdentitySignal.value.$phone_number,
                  }
                : undefined,
            })
          }
        )
      }
    },
    [datahappy, cartContext, currencyCode, products]
  )

  const changeQuantity = useCallback(
    async ({ product, variant }: ProductVariantEntry, qty: number) => {
      await cartContext.storefront.updateProducts([
        {
          productId: product.productId,
          variantId: variant.variantId,
          quantity: qty,
        },
      ])
    },
    [cartContext]
  )

  const removeProduct = useCallback(
    async ({ line }: ProductVariantEntry) => {
      if (line) {
        const removeLine = cartContext.storefront.cart?.lines.edges.find(
          (edge) => edge.node.id === line.id
        )
        await cartContext.storefront.removeProducts([line.id])
        if (removeLine) {
          const dlproducts = ecommerceInfoFromLineItems([removeLine], products)
          // @TODO move to Datahappy?
          pushEvent({
            event: 'dl_remove_from_cart',
            user_data: klaviyoIdentitySignal.value
              ? {
                  email: klaviyoIdentitySignal.value.$email,
                  phone_number: klaviyoIdentitySignal.value.$phone_number,
                }
              : {},
            ecommerce: {
              currencyCode,
              value:
                cartContext.storefront.cart?.lines.edges.reduce(
                  (total, edge) =>
                    edge.node.id === line.id
                      ? total
                      : total + edge.node.estimatedCost.subtotalAmount.amount,
                  0
                ) ?? 0,
              detail: { products: dlproducts },
            },
          })
        }
      }
    },
    [pushEvent, cartContext, currencyCode, products]
  )

  const applyDiscountCode = useCallback(
    async (code: string) => {
      await cartContext.storefront.updateDiscountCodes([code])
    },
    [cartContext]
  )

  const removeDiscountCode = useCallback(async () => {
    if (cartContext.storefront.cart) {
      await cartContext.storefront.updateDiscountCodes([])
    }
  }, [cartContext])

  const checkout = useCallback(async () => {
    if (cartContext.storefront.cart?.checkoutUrl) {
      await klaviyoIdentityPromiseSignal.value

      const url = new URL(cartContext.storefront.cart.checkoutUrl)
      url.search = qs.stringify({
        ...qs.parse(url.search),
        ...queryParamsSignal.value,
      })
      window.location.assign(url.toString())
    }
  }, [cartContext])

  return useMemo(
    () => ({
      addSuggestedProduct,
      changeQuantity,
      removeProduct,
      applyDiscountCode,
      removeDiscountCode,
      checkout,
    }),
    [
      addSuggestedProduct,
      changeQuantity,
      removeProduct,
      applyDiscountCode,
      removeDiscountCode,
      checkout,
    ]
  )
}
