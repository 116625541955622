import React, { useContext, useMemo } from 'react'
import compact from 'just-compact'
import { Locales, MenuLocation, NavTheme } from '../../constants'
import { Nav } from '@heights/heights-ui-components'
import { useRouter } from 'next/router'
import { CartContext } from '@/context'
import { useMenu, useMenuExperiments } from '@/hooks'
import {
  contentfulMenuItemAsNavData,
  filterMenuItemsByExperiments,
} from '@/utils/contentful'
import { Box } from 'theme-ui'
import { useCtaAction } from '@/hooks/useCtaAction'
import { openCart } from '@/signals'

type NavProps = React.ComponentProps<typeof Nav>
type NavData = NonNullable<NavProps['menuItems']>

type Props = {
  navTheme?: NavTheme
  navMode?: 'full' | 'basic'
  transparentNav?: boolean
  bannersHeight?: number
}

export const LayoutNav: React.FC<Props> = ({
  navTheme = NavTheme.BRAND,
  navMode = 'full',
  transparentNav,
  bannersHeight,
}) => {
  // @TODO I don't believe we use this at the moment
  // const { isLoggedIn } = useAuthContext()
  const router = useRouter()
  const cartContext = useContext(CartContext)
  const primaryMenu = useMenu(MenuLocation.PRIMARY)
  const primaryMenuExperiments = useMenuExperiments(primaryMenu)

  const navData = useMemo<NavData>(() => {
    if (navMode == 'basic') {
      return []
    }

    return (
      filterMenuItemsByExperiments(
        compact(primaryMenu?.menuItems ?? []),
        primaryMenuExperiments
      ).map((menuItem) => contentfulMenuItemAsNavData(menuItem)) ?? []
    )
  }, [navMode, primaryMenu, primaryMenuExperiments])

  const ctaAction = useCtaAction(primaryMenu?.ctaAction)

  const cartItems =
    cartContext.storefront.cart?.lines.edges.reduce(
      (previous, current) => previous + current.node.quantity,
      0
    ) ?? 0

  return (
    <Box
      sx={{
        height: transparentNav ? 0 : 'auto',
      }}
    >
      <Nav
        key={router?.asPath}
        sticky={false}
        theme={navTheme}
        locales={Locales}
        homepageUrl="/"
        accountUrl={
          navMode === 'basic'
            ? null
            : 'https://shop.yourheights.com/a/account/login'
        }
        transparent={transparentNav}
        overlayTopOffset={bannersHeight}
        menuItems={navData}
        withCart={navMode !== 'basic'}
        isCartEmpty={
          (cartContext.storefront.cart?.lines.edges.length || 0) === 0
        }
        cartItems={cartItems}
        onCartClick={openCart}
        cta={navMode !== 'basic' && ctaAction ? ctaAction : undefined}
      />
    </Box>
  )
}
