import React, { useRef } from 'react'
import useSize from '@react-hook/size'
import { NavTheme } from '@/constants'
import { PageTopBanner } from '@heights/heights-ui-library'
import { Box } from 'theme-ui'
import { AnnouncementBanner } from '@/components'
import { LayoutNav } from '../LayoutNav'
import { useGlobalData } from '@/hooks'

type Props = {
  navTheme?: NavTheme
  navMode?: 'full' | 'basic'
  transparentNav?: boolean
  hideAnnouncementBanner?: boolean
  isProductPage?: boolean
  miniBannerContent?: React.ReactNode
}

export const LayoutHeader: React.FC<Props> = ({
  navTheme,
  navMode = 'full',
  transparentNav,
  hideAnnouncementBanner,
  isProductPage,
  miniBannerContent,
}) => {
  const bannersRef = useRef<HTMLDivElement | null>(null)
  const [, bannersHeight] = useSize(bannersRef)
  const globalData = useGlobalData()

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          left: 0,
          zIndex: 'high',
        }}
      >
        <Box ref={bannersRef}>
          {!hideAnnouncementBanner &&
            (!isProductPage && !!miniBannerContent ? (
              <AnnouncementBanner>{miniBannerContent}</AnnouncementBanner>
            ) : (
              <PageTopBanner
                backgroundColor={
                  globalData?.fields?.miniBannerContentBackgroundColor ??
                  undefined
                }
                foregroundColor={
                  globalData?.fields?.miniBannerContentForegroundColor ??
                  undefined
                }
                items={[
                  {
                    label: '30 day money back guarantee',
                    icon: 'subsCancel',
                  },
                  {
                    label: 'Cancel any time',
                    icon: 'time',
                  },
                  {
                    label: 'Monthly delivery',
                    icon: 'subsDelivery',
                  },
                  {
                    label: 'Through the letterbox',
                    icon: 'subsLetterbox',
                  },
                ]}
              />
            ))}
        </Box>

        <LayoutNav
          navTheme={navTheme}
          navMode={navMode}
          transparentNav={transparentNav}
          bannersHeight={bannersHeight}
        />
      </Box>
    </>
  )
}
