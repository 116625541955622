import {
  backgroundColors,
  foregroundColors,
} from '@/contentful/utils/commonValues'
import { IconNames } from '@heights/heights-icons'
import { defineDocument, defineObject } from 'sanity-typed-queries'
import { asset } from './asset'
import { ctaAction } from './ctaAction'
import { JsonType } from './JsonType'
import { mediaPlaybackCta } from './mediaPlaybackCta'

const blockCardItemFields = defineObject(
  'blockCardItemFields',
  {
    reference: { type: 'string' },
    foregroundColor: {
      type: 'string',
      options: { list: foregroundColors },
    },
    foregroundCtaColor: {
      type: 'string',
      options: { list: foregroundColors },
    },
    label: { type: 'string' },
    icon: {
      type: 'string',
      options: { list: Object.values(IconNames) },
    },
    title: { type: 'string' },
    descriptionRT: { type: 'json' },
    ctaType: {
      type: 'string',
      options: { list: ['link' as const, 'button' as const] },
    },
    ctaText: { type: 'string' },
    ctaLink: { type: 'string' },
    ctaAction: {
      type: 'reference',
      to: [{ type: 'ctaAction' }],
    },
    deprecatedCtaAction: {
      type: 'string',
    },
    image: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    mediaDate: { type: 'string' },
    mediaDurationInMinutes: { type: 'number' },
    mediaPlayback: { type: 'reference', to: [{ type: 'mediaPlaybackCta' }] },
    fullWidth: { type: 'boolean' },
    backgroundColor: {
      type: 'string',
      options: { list: backgroundColors },
    },
  },
  [
    {} as JsonType,
    ctaAction.ctaAction,
    asset.asset,
    mediaPlaybackCta.mediaPlaybackCta,
  ]
)

export const blockCardItem = defineDocument(
  'blockCardItem',
  {
    fields: {
      type: 'blockCardItemFields',
    },
  },
  [blockCardItemFields.blockCardItemFields]
)
