import qs from 'query-string'

export type Options = {
  width?: number
  height?: number
  fit?: 'pad' | 'fill' | 'scale' | 'crop' | 'thumbs'
  focus?:
    | 'center'
    | 'top'
    | 'right'
    | 'left'
    | 'bottom'
    | 'top_right'
    | 'top_left'
    | 'bottom_right'
    | 'bottom_left'
    | 'face'
    | 'faces'
  radius?: number
  backgroundColor?: string
  quality?: number
  preserveInput?: boolean
}

export function transformImageUrl(url: string, options?: Options) {
  const hasRelativeProtocol = !!url.match(/^\/\//)
  const preserveInput = options?.preserveInput ?? true
  const [base, query] = url.split('?')
  const params = preserveInput ? qs.parse(query) : {}

  if (typeof options?.width === 'number') {
    params.w = String(options.width)
  }

  if (typeof options?.height === 'number') {
    params.h = String(options.height)
  }

  if (typeof options?.fit === 'string') {
    params.fit = options.fit
  }

  if (typeof options?.focus === 'string') {
    params.f = options.focus
  }

  if (typeof options?.quality === 'number') {
    params.q = String(options.quality)
  }

  if (typeof options?.radius === 'number') {
    params.r = String(options.radius)
  }

  if (typeof options?.backgroundColor === 'number') {
    params.bg = options.backgroundColor
  }

  return `${!hasRelativeProtocol ? base : `https:${base}`}?${qs.stringify(
    params
  )}`
}
