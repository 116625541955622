import React, { useCallback, useContext } from 'react'
import { useProducts } from './useProducts'
import type { VariantIdType } from '@/types/VariantIdType'
import { CartContext } from '@/context'
import { decodeShopifyId } from '@/utils/shopify'
import { ecommerceInfoFromLineItems } from '@/utils/shopify'
import { useCurrency } from './useCurrency'
import { createKlaviyoIdentitySignals, openCart } from '@/signals'
import { useDatahappy } from './useDatahappy'

type LineItem = {
  quantity: number
  productId: number
  variantId: VariantIdType
}

// @README: Left for future use
const [klaviyoIdentity, klaviyoIdentityPromise] = createKlaviyoIdentitySignals()

export function useHandleCheckout(locale: string) {
  const cartContext = useContext(CartContext)
  const { currencyCode } = useCurrency()
  const products = useProducts()
  const [datahappy] = useDatahappy()

  const getFinalLineItems = useCallback(
    (inputLineItems: LineItem[]) => {
      const inputLineItemIds = inputLineItems.map(
        (lineItem) => lineItem.variantId
      )
      const automaticProducts = Object.values(products).reduce<LineItem[]>(
        (acc, product) => {
          product.variants.forEach((variant) => {
            if (
              variant.includeWithAnyOrder &&
              !acc.some(({ variantId }) => variantId === variant.variantId) &&
              !inputLineItemIds.includes(variant.variantId) &&
              (!variant.includeWithAnyOrderRequirements?.length ||
                variant.includeWithAnyOrderRequirements.some(({ variantId }) =>
                  inputLineItemIds.includes(variantId)
                ))
            ) {
              acc.push({
                productId: product.productId,
                variantId: variant.variantId,
                quantity: 1,
              })
            }
          })
          return acc
        },
        []
      )
      return inputLineItems.concat(automaticProducts)
    },
    [products]
  )

  const doCartCheckout = useCallback(
    async (lineItems: LineItem[]) => {
      await klaviyoIdentityPromise.value

      const lineItemVariantIds = lineItems.map((lineItem) =>
        decodeShopifyId(lineItem.variantId)
      )
      const updatedCart = await cartContext.storefront.addProducts(
        lineItems.map((item) => ({
          ...item,
          quantity: item.quantity || 1,
        }))
      )

      const updatedLines =
        updatedCart?.lines.edges.filter((item) =>
          lineItemVariantIds.includes(decodeShopifyId(item.node.merchandise.id))
        ) ?? []
      ecommerceInfoFromLineItems(updatedLines, products).forEach((product) => {
        const ecommerceInfo = {
          currencyCode,
          value: product.price,
          detail: {
            products: [product],
          },
        }

        datahappy.trackAddToCart(ecommerceInfo, {
          userTraits: klaviyoIdentity.value
            ? {
                email: klaviyoIdentity.value.$email,
                phone: klaviyoIdentity.value.$phone_number,
              }
            : {},
        })
      })

      openCart()
    },
    [cartContext, currencyCode, datahappy, products]
  )

  return React.useCallback(
    async (inputLineItems: LineItem[]): Promise<void | null> => {
      const allLineItems = getFinalLineItems(inputLineItems)
      return doCartCheckout(allLineItems)
    },
    [getFinalLineItems, doCartCheckout]
  )
}
