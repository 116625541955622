import { BLUR_DATA_URL_SIZE } from './transformImage'
import { Options, transformImageUrl } from './transformImageUrl'

export function lqipImageUrl(url: string, options?: Options) {
  return transformImageUrl(url, {
    quality: 20,
    width: BLUR_DATA_URL_SIZE,
    fit: 'fill',
    focus: 'center',
    ...options,
  })
}
