import type { ImageAttrs } from '@heights/heights-ui-library'
import type { ContentfulMediaAssetFieldsFragmentFragment } from '@/graphql/contentful'
import type { CSSProperties } from 'react'
import { lqipImageUrl } from './lqipImageUrl'

type ImageOptions = {
  sizes?: string
  blurDataURL?: string
  fullscreen?: boolean
  layout?: 'constrained' | 'fixed' | 'fullWidth'
  objectFit?: CSSProperties['objectFit']
  objectPosition?: CSSProperties['objectPosition']
}

export const contentfulMediaAssetAsImageAttrs = (
  media?: Omit<
    ContentfulMediaAssetFieldsFragmentFragment,
    '__typename' | 'sys'
  > | null,
  imageOptions?: ImageOptions
): ImageAttrs | null => {
  if (media?.width && media.height && media.url) {
    return {
      alt: media.title ?? '',
      fullscreen: imageOptions?.fullscreen,
      image: {
        width: media.width,
        height: media.height,
        layout: imageOptions?.layout ?? ('constrained' as const),
        placeholder: {
          fallback: imageOptions?.blurDataURL
            ? imageOptions.blurDataURL
            : lqipImageUrl(media.url),
        },
        images: {
          fallback: {
            ...(imageOptions?.sizes ? { sizes: imageOptions.sizes } : {}),
            src: media.url,
          },
        },
      },
      ...(imageOptions?.objectFit
        ? { objectFit: imageOptions?.objectFit }
        : {}),
      ...(imageOptions?.objectPosition
        ? { objectPosition: imageOptions?.objectPosition }
        : {}),
    }
  }

  return null
}
