import { useABSplit } from '@/hooks'
import { useEffect } from 'react'

type Props = {
  id: string
  onStatusReceived: (id: string, status: boolean) => void
}

export function SingleExperimentHandler({ id, onStatusReceived }: Props) {
  const variant = useABSplit(id)
  useEffect(() => {
    if (typeof variant === 'number') {
      onStatusReceived(id, variant === 0 ? false : true)
    }
  }, [id, variant])

  return null
}
