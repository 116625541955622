import { useMemo } from 'react'
import type { CartFieldsFragmentFragment } from '@/graphql/storefront'
import { useDiscountCode } from '@/hooks'

type ResultValue = [boolean | null, boolean, string | null]

export function useDiscountOptions(cart: CartFieldsFragmentFragment | null) {
  const deepLinkDiscountCode = useDiscountCode()

  // @README to force Storefront cart to recalculate the lines
  // we are applying an "empty" discount code "" when clearing the discount code
  const actualDiscountCodes = useMemo(
    () => cart?.discountCodes.filter(({ code }) => !!code) ?? [],
    [cart]
  )

  const hasValidDiscountCode = useMemo(
    () => actualDiscountCodes.some(({ applicable }) => applicable) ?? null,
    [actualDiscountCodes]
  )

  const canCheckDiscountCodeStatus = useMemo(
    () =>
      (cart?.lines?.edges.length || 0) > 0 &&
      (actualDiscountCodes.length || 0) > 0,
    [cart, actualDiscountCodes]
  )

  const discountCode = useMemo(() => {
    const code =
      actualDiscountCodes?.find(({ applicable }) => !!applicable) ||
      actualDiscountCodes?.[0]
    if (code?.code) {
      return code?.code
    }

    if (!cart?.id) {
      return deepLinkDiscountCode
    }

    return null
  }, [cart, actualDiscountCodes, deepLinkDiscountCode])

  return useMemo<ResultValue>(
    () => [
      hasValidDiscountCode,
      canCheckDiscountCodeStatus,
      discountCode ?? null,
    ],
    [hasValidDiscountCode, canCheckDiscountCodeStatus, discountCode]
  )
}
