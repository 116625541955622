import type { ShallowMenuItemType } from '@/hooks/useMenu'

export function filterMenuItemsByExperiments(
  menuItems: ShallowMenuItemType[],
  experimentValues: Record<string, boolean> = {}
) {
  return menuItems.filter(
    (menuItem) =>
      !menuItem.experimentVariation?.fields?.experimentId ||
      (menuItem.experimentVariation.fields.variation === 'control' &&
        !experimentValues[menuItem.experimentVariation.fields.experimentId]) ||
      (menuItem.experimentVariation.fields.variation === 'variant' &&
        experimentValues[menuItem.experimentVariation.fields.experimentId])
  )
}
