import { ProductVariantModel } from '@/types'
import { formatPrice } from '@heights/heights-utils'

export function formatVariantFormattedString(
  str: string,
  variant: ProductVariantModel,
  locale: string,
  currencyCode: string
) {
  const currency = currencyCode || 'GBP'
  const price = variant.costPerChargeFrequency[currencyCode]
  const asBasePrice =
    variant.baseCostPerChargeFrequency?.[currencyCode] ?? price
  const pricePerOrder = variant.costPerIntervalFrequency[currencyCode]

  const formattedPrice = formatPrice(price, locale, {
    currency,
  })
  const formattedAsBasePrice = formatPrice(asBasePrice, locale, {
    currency,
  })
  const formattedPricePerOrder = formatPrice(pricePerOrder, locale, {
    currency,
  })

  return str
    .replace(/\{price\}/gi, formattedPrice)
    .replace(/\{baseprice\}/gi, formattedAsBasePrice)
    .replace(/\{orderprice\}/gi, formattedPricePerOrder)
}
