import { useGlobalData } from '@/hooks'
import React, { FC, ReactNode } from 'react'
import { Box } from 'theme-ui'

export const ANNOUNCEMENT_BANNER_HEIGHT = 40

export const AnnouncementBanner: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const globalData = useGlobalData()

  return (
    <Box
      className="font-heading text-center [&_p]:m-0"
      sx={{
        bg: globalData?.fields?.miniBannerContentBackgroundColor ?? 'primary',
        color: globalData?.fields?.miniBannerContentForegroundColor ?? 'white',
        height: ANNOUNCEMENT_BANNER_HEIGHT,
        '&, p': {
          fontSize: [2, 4],
          lineHeight: `${ANNOUNCEMENT_BANNER_HEIGHT}px`,
        },
        'a, a:visited, a:link, a:hover': {
          color: 'inherit',
        },
      }}
    >
      {children}
    </Box>
  )
}
