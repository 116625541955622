import React, { FunctionComponent } from 'react'
import compact from 'just-compact'
import { LayoutModalsModal, modalQueryBuilder } from './LayoutModalsModal'
import { useData } from '@/hooks'
import { DataSourceType } from '@/hooks/useData'

type Props = {
  preventOpening?: boolean
}

const [query, type] = modalQueryBuilder.use()

export const LayoutModals: FunctionComponent<Props> = ({ preventOpening }) => {
  const reader = useData<typeof type>(DataSourceType.CONTENTFUL, query)
  const modals = reader()

  if (!modals) {
    return null
  }

  return (
    <>
      {compact(modals).map((modal) => (
        <LayoutModalsModal
          key={modal?._id}
          preventOpening={preventOpening}
          modal={modal}
        />
      ))}
    </>
  )
}
