import compact from 'just-compact'
import filterObject from 'just-filter-object'
import React, { useMemo, useContext } from 'react'
import { ModalContext, ModalContextValue } from '@heights/heights-context'
import { TCta } from '@heights/heights-react-hooks'
import { Theme } from '@/constants/index'
import { ctaAction } from '@/schemas'
import { useProducts } from './useProducts'
import { useHandleCheckout } from './useHandleCheckout'
import type {
  ProductsMapModel,
  ProductVariantModel,
  VariantIdType,
} from '@/types'
import { useIntl } from '@heights/heights-context'
import { PromiseStatus, usePromiseLoader } from './usePromiseLoader'
import { decodeShopifyId } from '@/utils/shopify'

type CtaActionFields = Omit<
  NonNullable<(typeof ctaAction.ctaAction)['fields']>,
  'linkContent'
> & {
  linkContent?: Pick<
    NonNullable<
      NonNullable<(typeof ctaAction.ctaAction)['fields']>['linkContent']
    >,
    '_ref' | '_type' | '_weak'
  >
}
type OptionalCtaAction = { fields?: Partial<CtaActionFields> }
type FieldsOrCtaAction = OptionalCtaAction | Partial<CtaActionFields>
type TransformCtaActionOptions = {
  disabled?: boolean
  modalContext?: ModalContextValue
  products?: ProductsMapModel
  onCheckout?: ReturnType<typeof useHandleCheckout>
}

export function transformContentfulCtaAction(
  action?: OptionalCtaAction,
  options: TransformCtaActionOptions = {}
): TCta | null {
  if (action?.fields) {
    const sharedProps = filterObject(
      {
        trackId: action.fields?.trackingId ?? undefined,
        disabled: options.disabled,
        theme: (action.fields.theme &&
        [Theme.TEAL, Theme.GREEN, Theme.WHITE, Theme.BABYBLUE].includes(
          action.fields.theme as Theme
        )
          ? action.fields.theme
          : undefined) as TCta['theme'],
        style: (action.fields.style ?? undefined) as TCta['style'],
        size: (action.fields.size ?? undefined) as TCta['size'],
      },
      (key, value) => !!value
    )

    if (action.fields.type === 'modal' && action.fields.contentId) {
      const contentId = action.fields.contentId
      return {
        ...sharedProps,
        type: 'button',
        label: action.fields.title ?? '',
        onClick: (event: React.SyntheticEvent) => {
          if (
            options.modalContext &&
            contentId in options.modalContext.modalControls
          ) {
            event.preventDefault()
            requestAnimationFrame(() => {
              options.modalContext?.modalControls[contentId].open()
            })
          }
        },
      }
    } else if (action.fields.type === 'typeform') {
      return {
        ...sharedProps,
        type: 'button',
        label: action.fields.title,
        onClick: () => {
          import('@typeform/embed').then((mod) => {
            const form = mod.createSlider(action.fields?.contentId ?? '', {
              position: 'right',
            })
            form.open()
          })
        },
      }
    } else if (
      action.fields.type === 'product-variant' &&
      action.fields.linkContent?._ref
    ) {
      return {
        ...sharedProps,
        type: 'button',
        label: action.fields.title,
        onClick: () => {
          const isSameVariant = (variant: ProductVariantModel) =>
            variant.contentfulId === action.fields?.linkContent?._ref
          const product = Object.values(options.products ?? {}).find(
            (product) => product.variants.some(isSameVariant)
          )
          const variant = product?.variants.find(isSameVariant)
          if (product && variant && options.onCheckout) {
            options.onCheckout([
              {
                productId: product.productId,
                quantity: 1,
                variantId: variant.variantId,
              },
            ])
          }
        },
      }
    } else if (action.fields.type === 'bundle' && action.fields.contentId) {
      const productIds = action.fields.contentId
        .split(',')
        .map((id) => id.trim())

      return {
        ...sharedProps,
        type: 'button',
        label: action.fields.title,
        onClick: () => {
          type LineItemsType = {
            productId: number
            variantId: VariantIdType
            quantity: number
          }

          const lineItems = productIds.reduce<LineItemsType[]>(
            (acc, variantId) => {
              const normVariantId = decodeShopifyId(variantId)
              let product: ProductsMapModel[string] | null = null
              let variantIndex = -1
              const allProducts = Object.values(options.products ?? {})
              for (let idx = 0; idx < allProducts.length; idx += 1) {
                const prod = allProducts[idx]
                const varIndex = prod.variants.findIndex(
                  (v) => decodeShopifyId(v.variantId) === normVariantId
                )
                if (prod && varIndex > -1) {
                  product = prod
                  variantIndex = varIndex
                  break
                }
              }
              if (product && variantIndex > -1) {
                acc.push({
                  productId: product.productId,
                  variantId: product.variants[variantIndex].variantId,
                  quantity: 1,
                })
              }
              return acc
            },
            []
          )

          options.onCheckout?.(lineItems)
        },
      }
    }

    return {
      ...sharedProps,
      type: 'link',
      label: action.fields.title,
      href: action.fields.url ?? '',
    }
  }

  return null
}

export function useTransformContentfulCtaAction() {
  const products = useProducts()
  const { locale, defaultLocale } = useIntl()
  const { load: handleCheckout, status: checkoutStatus } = usePromiseLoader(
    useHandleCheckout(locale ?? defaultLocale)
  )
  const modalContext = useContext(ModalContext)

  // @README adding modalContext to the useMemo deps causes an infinite loop
  // @TODO need to figure out why
  const transformOptions = useMemo(
    () => ({
      disabled: checkoutStatus === PromiseStatus.PENDING,
      modalContext,
      products,
      onCheckout: handleCheckout,
    }),
    [products, checkoutStatus, handleCheckout]
  )

  return useMemo(() => {
    function transformCta(action?: FieldsOrCtaAction): TCta | null
    function transformCta(actions?: FieldsOrCtaAction[]): TCta[] | null
    function transformCta(
      ctaActionOrActions?: FieldsOrCtaAction | FieldsOrCtaAction[]
    ): TCta | TCta[] | null {
      if (!ctaActionOrActions) return null

      if (Array.isArray(ctaActionOrActions)) {
        return compact(
          ctaActionOrActions.map((action) => {
            if (action) {
              return transformContentfulCtaAction(
                'fields' in action
                  ? action
                  : { fields: action as Partial<CtaActionFields> },
                transformOptions
              )
            }

            return null
          })
        )
      }

      if (ctaActionOrActions) {
        return transformContentfulCtaAction(
          'fields' in ctaActionOrActions
            ? ctaActionOrActions
            : { fields: ctaActionOrActions as Partial<CtaActionFields> },
          transformOptions
        )
      }

      return null
    }
    return transformCta
  }, [transformOptions])
}

export function useCtaAction(action?: FieldsOrCtaAction): TCta | null
export function useCtaAction(actions?: FieldsOrCtaAction[]): TCta[] | null
export function useCtaAction(
  ctaActionOrActions?: FieldsOrCtaAction | FieldsOrCtaAction[]
): TCta | TCta[] | null {
  const transformAction = useTransformContentfulCtaAction()

  return useMemo(
    () =>
      ctaActionOrActions && Array.isArray(ctaActionOrActions)
        ? transformAction(ctaActionOrActions)
        : transformAction(ctaActionOrActions),
    [ctaActionOrActions, transformAction]
  )
}
