import { DataSourceType, useData } from './useData'
import { section } from '@/schemas/section'

type QueryReturnType<T> = [string, T]

const sectionQueryBuilder = section.builder
  .map((entry) => ({
    backgroundImage: entry.fields.backgroundImage
      ?.resolve(['_id', 'fields'])
      .use(),
    mobileBackgroundImage: entry.fields.mobileBackgroundImage
      ?.resolve(['_id', 'fields'])
      .use(),
    ctaAction: entry.fields.ctaAction?.resolve('fields').use(),
    ctaActions: entry.fields.ctaActions?.resolveIn('fields').use(),
    product: entry.fields.product?.resolve(['_id', 'fields']).use(),
  }))
  .subquery({
    components: [
      `fields.components[]->{
        _id,
        _type,
        "_ref": _id,
        "type": fields.type,
        fields {
          title,
          trackingId,
          icon,
        }
      }`,
      [],
    ] as [
      string,
      ({
        _id: string
        _ref: string
        _type:
          | 'article'
          | 'blockCardItem'
          | 'blockCardImageItem'
          | 'ctaAction'
          | 'mediaPlaybackCta'
          | 'product'
          | 'productVariant'
          | 'subscriptionSection'
          | 'textItem'
          | 'section'
        type: string // @README this is only relevant for the section typ
        fields?: {
          title?: string
          trackingId?: string
          icon?: string
        }
      } | null)[]
    ],
  })

const [query, type] = sectionQueryBuilder.filter('_id == $id').first().use()

export function sectionDataHookFactory<
  Sub extends Record<string, QueryReturnType<any>>
>(subqueries: Sub) {
  const [customQuery, customType] = sectionQueryBuilder
    .filter('_id == $id')
    .subquery(subqueries)
    .first()
    .use()

  return function useSectionData(id: string) {
    const reader = useData<typeof customType>(
      DataSourceType.CONTENTFUL,
      customQuery,
      { id }
    )
    return reader()
  }
}

export function useSectionData(id: string) {
  const reader = useData<typeof type>(DataSourceType.CONTENTFUL, query, { id })
  return reader()
}
