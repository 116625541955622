import dynamic from 'next/dynamic'
import React from 'react'
import type { SingleComponentPickerItem } from '../../SingleComponentPicker'

type Props = {
  data?: SingleComponentPickerItem
}
const DynamicContentfulSectionBasicSection = dynamic(
  () => import('./ContentfulSectionBasicSection')
)
const DynamicContentHighlight = dynamic(
  () => import('./ContentfulSectionContentHighlight')
)
const DynamicContentfulSectionBhsSection = dynamic(
  () => import('./ContentfulSectionBhsSection')
)
const DynamicContentfulSectionUgcSection = dynamic(
  () => import('./ContentfulSectionUgcSection')
)
const DynamicContentfulSectionBhsScoreSection = dynamic(
  () => import('./ContentfulSectionBhsScoreSection')
)
const DynamicContentfulSectionRelatedArticles = dynamic(
  () => import('./ContentfulSectionRelatedArticles')
)
const DynamicContentfulSectionContentCardsSection = dynamic(
  () => import('./ContentfulSectionContentCardsSection')
)
const DynamicContentfulSectionBillboardImage = dynamic(
  () => import('./ContentfulSectionBillboardImage')
)
const DynamicContentfulSectionImageHighlightSection = dynamic(
  () => import('./ContentfulSectionImageHighlightSection')
)
const DynamicContentfulSectionImageNavigationCardsSection = dynamic(
  () => import('./ContentfulSectionImageNavigationCardsSection')
)
const DynamicContentfulSectionProductGallerySection = dynamic(
  () => import('./ContentfulSectionProductGallerySection')
)
const DynamicContentfulSectionFaqSection = dynamic(
  () => import('./ContentfulSectionFaqSection')
)
const DynamicContentfulSectionIngredientUspSection = dynamic(
  () => import('./ContentfulSectionIngredientUspSection')
)
const DynamicContentfulSectionInlineVideoSection = dynamic(
  () => import('./ContentfulSectionInlineVideoSection')
)
const DynamicContentfulSectionIngredientsSection = dynamic(
  () => import('./ContentfulSectionIngredientsSection')
)
const DynamicContentfulSectionTabSection = dynamic(
  () => import('./ContentfulSectionTabSection')
)
const DynamicContentfulSectionUspGridSection = dynamic(
  () => import('./ContentfulSectionUspGridSection')
)
const DynamicContentfulSectionBloodTrialSection = dynamic(
  () => import('./ContentfulSectionBloodTrialSection')
)
const DynamicContentfulSectionOkendo = dynamic(
  () => import('./ContentfulSectionOkendo')
)
const DynamicContentfulSectionBaseSection = dynamic(
  () => import('./ContentfulSectionBaseSection')
)
const DynamicContentfulSectionProductBlock = dynamic(
  () => import('./ContentfulSectionProductBlock')
)
const DynamicContentfulSectionExpertsSection = dynamic(
  () => import('./ContentfulSectionExpertsSection')
)
const DynamicContentfulSectionSeenInStrip = dynamic(
  () => import('./ContentfulSectionSeenInStrip')
)
const DynamicContentfulSectionIngredientsGrid = dynamic(
  () => import('./ContentfulSectionIngredientsGrid')
)
const DynamicContentfulSectionNewsletterSubscribeBanner = dynamic(
  () => import('./ContentfulSectionNewsletterSubscribeBanner')
)
const DynamicContentfulSectionUgcMasonrySection = dynamic(
  () => import('./ContentfulSectionUgcMasonrySection')
)
const DynamicContentfulSectionResearchStudiesTableSection = dynamic(
  () => import('./ContentfulSectionResearchStudiesTableSection')
)
const DynamicContentfulSectionCustomerTestimonialCarousel = dynamic(
  () => import('./ContentfulSectionCustomerTestimonialCarousel')
)
const DynamicContentfulSectionScientificEvidenceSection = dynamic(
  () => import('./ContentfulSectionScientificEvidenceSection')
)
const DynamicContentfulSectionCommunityStoriesCarousel = dynamic(
  () => import('./ContentfulSectionCommunityStoriesCarousel')
)
const DynamicContentfulSectionSubscribeBanner = dynamic(
  () => import('./ContentfulSectionSubscribeBanner')
)
const DynamicContentfulSectionMarqueeBanner = dynamic(
  () => import('./ContentfulSectionMarqueeBanner')
)
const DynamicContentfulSectionVideoTestimonial = dynamic(
  () => import('./ContentfulSectionVideoTestimonial')
)
const DynamicContentfulSectionSimpleCtaBanner = dynamic(
  () => import('./ContentfulSectionSimpleCtaBanner')
)
const DynamicContentfulSectionStickySnackbar = dynamic(
  () => import('./ContentfulSectionStickySnackbar')
)
const DynamicContentfulSectionTableOfContents = dynamic(
  () => import('./ContentfulSectionTableOfContents')
)
const DynamicContentfulSectionQuoteCarousel = dynamic(
  () => import('./ContentfulSectionQuoteCarousel')
)
const DynamicContentfulSectionImage = dynamic(
  () => import('./ContentfulSectionImage')
)
const DynamicContentfulSectionSubscribeForm = dynamic(
  () => import('./ContentfulSectionSubscribeForm')
)
const DynamicContentfulSectionBuyboxSection = dynamic(
  () => import('./ContentfulSectionBuyBoxSection')
)
const DynamicContentfulSectionContactUsSection = dynamic(
  () => import('./ContentfulSectionContactUs')
)
const DynamicContentfulSectionBenefitSection = dynamic(
  () => import('./ContentfulSectionBenefitSection')
)
const DynamicContentfulSectionCollapsibleTableSection = dynamic(
  () => import('./ContentfulSectionCollapsibleTableSection')
)
const DynamicContentfulSectionEmbeddedAccordionSection = dynamic(
  () => import('./ContentfulSectionEmbeddedAccordionSection')
)
const DynamicContentfulSectionEmbeddedBenefitSection = dynamic(
  () => import('./ContentfulSectionEmbeddedBenefitSection')
)
const DynamicContentfulSectionMediaPlayback = dynamic(
  () => import('./ContentfulSectionMediaPlayback')
)
const DynamicContentfulSectionComparisonSection = dynamic(
  () => import('./ContentfulSectionComparisonSection')
)

export const ContentfulSection: React.FC<Props> = ({ data }) => {
  if (!data) return null

  const type = data.type

  switch (type) {
    case 'basic-section':
      return <DynamicContentfulSectionBasicSection data={data} />
    case 'centered-basic-section':
      return <DynamicContentfulSectionBasicSection centered data={data} />
    case 'content-highlight':
      return <DynamicContentHighlight data={data} />
    case 'bhs-section':
      return <DynamicContentfulSectionBhsSection id={type} data={data} />
    case 'ugc-section':
      return <DynamicContentfulSectionUgcSection data={data} />
    case 'bhs-score-section':
      return <DynamicContentfulSectionBhsScoreSection data={data} />
    case 'related-articles':
      return <DynamicContentfulSectionRelatedArticles data={data} />
    case 'content-cards-section':
      return <DynamicContentfulSectionContentCardsSection data={data} />
    case 'billboard-image':
      return <DynamicContentfulSectionBillboardImage data={data} />
    case 'image-highlight-section':
      return <DynamicContentfulSectionImageHighlightSection data={data} />
    case 'image-navigation-cards-section':
      return <DynamicContentfulSectionImageNavigationCardsSection data={data} />
    case 'product-gallery':
      return <DynamicContentfulSectionProductGallerySection data={data} />
    case 'faq-section':
      return <DynamicContentfulSectionFaqSection data={data} />
    case 'ingredients-usp':
      return <DynamicContentfulSectionIngredientUspSection data={data} />
    case 'inline-video-section':
      return <DynamicContentfulSectionInlineVideoSection data={data} />
    case 'ingredients-table':
      return <DynamicContentfulSectionIngredientsSection data={data} />
    case 'tab-section':
      return <DynamicContentfulSectionTabSection data={data} />
    case 'usp-grid-section':
      return <DynamicContentfulSectionUspGridSection data={data} />
    case 'blood-trial-section/basic':
      return (
        <DynamicContentfulSectionBloodTrialSection
          variant="basic"
          data={data}
        />
      )
    case 'okendo-reviews':
      return <DynamicContentfulSectionOkendo data={data} />
    case 'okendo-reviews-carousel':
      return <DynamicContentfulSectionOkendo widget="carousel" data={data} />
    case 'rich-text':
      return (
        <DynamicContentfulSectionBaseSection data={data} textAlign="left" />
      )
    case 'product-block-inline':
      return (
        <DynamicContentfulSectionProductBlock
          variant="intermezzo"
          data={data}
        />
      )
    case 'product-block':
      return (
        <DynamicContentfulSectionProductBlock variant="inline" data={data} />
      )
    case 'experts-section':
      return <DynamicContentfulSectionExpertsSection data={data} />
    case 'seen-in-strip':
      return <DynamicContentfulSectionSeenInStrip data={data} />
    case 'ingredients-grid':
      return (
        <DynamicContentfulSectionIngredientsGrid carousel={false} data={data} />
      )
    case 'newsletter-subscribe-banner':
      return <DynamicContentfulSectionNewsletterSubscribeBanner data={data} />
    case 'ugc-masonry-section':
      return <DynamicContentfulSectionUgcMasonrySection data={data} />
    case 'research-studies-table-section':
      return <DynamicContentfulSectionResearchStudiesTableSection data={data} />
    case 'customer-testimonial-carousel':
      return <DynamicContentfulSectionCustomerTestimonialCarousel data={data} />
    case 'scientific-evidence-section':
      return <DynamicContentfulSectionScientificEvidenceSection data={data} />
    case 'community-stories-carousel':
      return <DynamicContentfulSectionCommunityStoriesCarousel data={data} />
    case 'subscribe-banner':
      return <DynamicContentfulSectionSubscribeBanner data={data} />
    case 'marquee-banner':
      return <DynamicContentfulSectionMarqueeBanner data={data} />
    case 'video-testimonial':
      return <DynamicContentfulSectionVideoTestimonial data={data} />
    case 'simple-cta-banner':
      return <DynamicContentfulSectionSimpleCtaBanner data={data} />
    case 'sticky-snackbar':
      return <DynamicContentfulSectionStickySnackbar data={data} />
    case 'table-of-contents':
      return <DynamicContentfulSectionTableOfContents data={data} />
    case 'quote-carousel':
      return <DynamicContentfulSectionQuoteCarousel data={data} />
    case 'cover-image':
      return <DynamicContentfulSectionImage cover data={data} />
    case 'subscribe-form-large':
      return (
        <DynamicContentfulSectionSubscribeForm hasLargeButton data={data} />
      )
    case 'buybox-section':
      return <DynamicContentfulSectionBuyboxSection data={data} />
    case 'contact-us':
      return <DynamicContentfulSectionContactUsSection data={data} />
    case 'benefit-section':
      return <DynamicContentfulSectionBenefitSection data={data} />
    case 'collapsible-table-section':
      return <DynamicContentfulSectionCollapsibleTableSection data={data} />
    case 'embedded-benefit':
      return <DynamicContentfulSectionEmbeddedBenefitSection data={data} />
    case 'embedded-accordion':
      return <DynamicContentfulSectionEmbeddedAccordionSection data={data} />
    case 'media-playback':
      return <DynamicContentfulSectionMediaPlayback data={data} />
    case 'comparison-section':
      return <DynamicContentfulSectionComparisonSection data={data} />
    default:
      return <DynamicContentfulSectionBaseSection data={data} />
  }
}
