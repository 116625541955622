import React, { ComponentProps, PropsWithChildren, ReactNode } from 'react'
import { ProductModel, SharedPageProps } from '@/types'
import { SeoFields } from '@/components/SeoFields'
import { LayoutContext } from '@/context'
import { Box } from 'theme-ui'
import { LayoutHeader, LayoutFooter, LayoutModals, Cart } from '@/components'
import { NavTheme } from '@/constants'

export type LayoutProps = PropsWithChildren<
  SharedPageProps & {
    navTheme?: NavTheme
    navMode?: 'full' | 'basic'
    transparentNav?: boolean
    isProductPage?: boolean
    isEmbeddedPage?: boolean
    miniBannerContent?: ReactNode
    hideAnnouncementBanner?: boolean
    pageProduct?: ProductModel | null
    seo?: ComponentProps<typeof SeoFields>
  }
>

export const Layout: React.FC<LayoutProps> = ({
  children,
  navTheme = NavTheme.BRAND,
  navMode = 'full',
  transparentNav,
  isProductPage,
  isEmbeddedPage,
  miniBannerContent,
  hideAnnouncementBanner,
  pageProduct,
  seo,
}) => {
  return (
    <LayoutContext.Provider
      value={{
        transparentNavbar: Boolean(transparentNav),
        pageProduct: pageProduct ? pageProduct : null,
      }}
    >
      {!isEmbeddedPage && <SeoFields {...seo} />}

      {!isEmbeddedPage && (
        <LayoutHeader
          navTheme={navTheme}
          navMode={navMode}
          transparentNav={transparentNav}
          hideAnnouncementBanner={hideAnnouncementBanner}
          isProductPage={isProductPage}
          miniBannerContent={miniBannerContent}
        />
      )}

      <Box as="main">{children}</Box>

      {!isEmbeddedPage && (
        <>
          <LayoutFooter />
          <LayoutModals />
        </>
      )}

      <Cart />
    </LayoutContext.Provider>
  )
}
