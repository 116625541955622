import { ProductModel } from '@/types'
import { createContext } from 'react'

export type LayoutContextValue = {
  transparentNavbar: boolean
  pageProduct: ProductModel | null
}

export const LayoutContext = createContext<LayoutContextValue>({
  transparentNavbar: false,
  pageProduct: null,
})
